
package eu.karelhovorka.numbers.generated

val upgradesCSV = """Name,Description-cs,Description-en,Type,SubType,Level,Id,Action,Absolute,Relative,Absolute difference,Cost,Order,RequiredIds,Enabled,Comment,,abs,rel,cost abs,cost rel,FORMULA,A ,B, C
Passive/Absolute/0,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,0,Passive/Absolute/0,UPDATE,1,1,1,8,0,,TRUE,,Formula1,1,1.08,50,1,passive upgrade absolute value,0.011,1,1
Passive/Absolute/1,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,1,Passive/Absolute/1,UPDATE,2,1,1,37,0,,TRUE,,,10,1.07,100,1,passive upgrade absolute price,4,25,8
Passive/Absolute/2,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,2,Passive/Absolute/2,UPDATE,3,1,1,74,0,,TRUE,,estimated test score,1689358,,,,click absolute value,0.1,3,3
Passive/Absolute/3,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,3,Passive/Absolute/3,UPDATE,4,1,1,119,0,,TRUE,,estimated average,88,,,,clilck absolute price,1.5,50,10
Passive/Absolute/4,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,4,Passive/Absolute/4,UPDATE,5,1,1,172,0,,TRUE,,,,,,,tier price,180,2000,1000
Passive/Absolute/5,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,5,Passive/Absolute/5,UPDATE,6,1,1,233,0,,TRUE,,,,,,,maximum value,200,1000,1000
Passive/Absolute/6,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,6,Passive/Absolute/6,UPDATE,7,1,1,302,0,,TRUE,,,,,,,maximum price,0,800,400
Passive/Absolute/7,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,7,Passive/Absolute/7,UPDATE,9,1,1,379,0,,TRUE,,,,,,,,,,
Passive/Absolute/8,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,8,Passive/Absolute/8,UPDATE,10,1,1,464,0,,TRUE,,,,,,,,,,
Passive/Absolute/9,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,9,Passive/Absolute/9,UPDATE,11,1,1,557,0,,TRUE,,,,,,,,,,
Passive/Absolute/10,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,10,Passive/Absolute/10,UPDATE,12,1,2,658,0,Tier/Base/0,TRUE,,,,,,,,,,
Passive/Absolute/11,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,11,Passive/Absolute/11,UPDATE,13,1,1,767,0,,TRUE,,,,,,,,,,
Passive/Absolute/12,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,12,Passive/Absolute/12,UPDATE,15,1,1,884,0,,TRUE,,,,,,,,,,
Passive/Absolute/13,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,13,Passive/Absolute/13,UPDATE,16,1,1,1009,0,,TRUE,,,,,,,,,,
Passive/Absolute/14,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,14,Passive/Absolute/14,UPDATE,17,1,2,1142,0,,TRUE,,,,,,,,,,
Passive/Absolute/15,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,15,Passive/Absolute/15,UPDATE,18,1,1,1283,0,,TRUE,,,,,,,,,,
Passive/Absolute/16,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,16,Passive/Absolute/16,UPDATE,20,1,1,1432,0,,TRUE,,,,,,,,,,
Passive/Absolute/17,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,17,Passive/Absolute/17,UPDATE,21,1,2,1589,0,,TRUE,,,,,,,,,,
Passive/Absolute/18,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,18,Passive/Absolute/18,UPDATE,23,1,1,1754,0,,TRUE,,,,,,,,,,
Passive/Absolute/19,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,19,Passive/Absolute/19,UPDATE,24,1,1,1927,0,,TRUE,,,,,,,,,,
Passive/Absolute/20,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,20,Passive/Absolute/20,UPDATE,25,1,2,2108,0,Tier/Base/1,TRUE,,,,,,,,,,
Passive/Absolute/21,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,21,Passive/Absolute/21,UPDATE,27,1,1,2297,0,,TRUE,,,,,,,,,,
Passive/Absolute/22,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,22,Passive/Absolute/22,UPDATE,28,1,2,2494,0,,TRUE,,,,,,,,,,
Passive/Absolute/23,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,23,Passive/Absolute/23,UPDATE,30,1,1,2699,0,,TRUE,,,,,,,,,,
Passive/Absolute/24,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,24,Passive/Absolute/24,UPDATE,31,1,2,2912,0,,TRUE,,,,,,,,,,
Passive/Absolute/25,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,25,Passive/Absolute/25,UPDATE,33,1,1,3133,0,,TRUE,,,,,,,,,,
Passive/Absolute/26,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,26,Passive/Absolute/26,UPDATE,34,1,2,3362,0,,TRUE,,,,,,,,,,
Passive/Absolute/27,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,27,Passive/Absolute/27,UPDATE,36,1,2,3599,0,,TRUE,,,,,,,,,,
Passive/Absolute/28,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,28,Passive/Absolute/28,UPDATE,38,1,1,3844,0,,TRUE,,,,,,,,,,
Passive/Absolute/29,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,29,Passive/Absolute/29,UPDATE,39,1,2,4097,0,,TRUE,,,,,,,,,,
Passive/Absolute/30,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,30,Passive/Absolute/30,UPDATE,41,1,1,4358,0,Tier/Base/2,TRUE,,,,,,,,,,
Passive/Absolute/31,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,31,Passive/Absolute/31,UPDATE,43,1,2,4627,0,,TRUE,,,,,,,,,,
Passive/Absolute/32,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,32,Passive/Absolute/32,UPDATE,44,1,2,4904,0,,TRUE,,,,,,,,,,
Passive/Absolute/33,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,33,Passive/Absolute/33,UPDATE,46,1,1,5189,0,,TRUE,,,,,,,,,,
Passive/Absolute/34,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,34,Passive/Absolute/34,UPDATE,48,1,2,5482,0,,TRUE,,,,,,,,,,
Passive/Absolute/35,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,35,Passive/Absolute/35,UPDATE,49,1,2,5783,0,,TRUE,,,,,,,,,,
Passive/Absolute/36,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,36,Passive/Absolute/36,UPDATE,51,1,2,6092,0,,TRUE,,,,,,,,,,
Passive/Absolute/37,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,37,Passive/Absolute/37,UPDATE,53,1,2,6409,0,,TRUE,,,,,,,,,,
Passive/Absolute/38,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,38,Passive/Absolute/38,UPDATE,55,1,1,6734,0,,TRUE,,,,,,,,,,
Passive/Absolute/39,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,39,Passive/Absolute/39,UPDATE,57,1,2,7067,0,,TRUE,,,,,,,,,,
Passive/Absolute/40,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,40,Passive/Absolute/40,UPDATE,59,1,2,7408,0,Tier/Base/3,TRUE,,,,,,,,,,
Passive/Absolute/41,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,41,Passive/Absolute/41,UPDATE,60,1,2,7757,0,,TRUE,,,,,,,,,,
Passive/Absolute/42,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,42,Passive/Absolute/42,UPDATE,62,1,2,8114,0,,TRUE,,,,,,,,,,
Passive/Absolute/43,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,43,Passive/Absolute/43,UPDATE,64,1,2,8479,0,,TRUE,,,,,,,,,,
Passive/Absolute/44,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,44,Passive/Absolute/44,UPDATE,66,1,2,8852,0,,TRUE,,,,,,,,,,
Passive/Absolute/45,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,45,Passive/Absolute/45,UPDATE,68,1,2,9233,0,,TRUE,,,,,,,,,,
Passive/Absolute/46,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,46,Passive/Absolute/46,UPDATE,70,1,2,9622,0,,TRUE,,,,,,,,,,
Passive/Absolute/47,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,47,Passive/Absolute/47,UPDATE,72,1,2,10019,0,,TRUE,,,,,,,,,,
Passive/Absolute/48,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,48,Passive/Absolute/48,UPDATE,74,1,2,10424,0,,TRUE,,,,,,,,,,
Passive/Absolute/49,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,49,Passive/Absolute/49,UPDATE,76,1,2,10837,0,,TRUE,,,,,,,,,,
Passive/Absolute/50,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,50,Passive/Absolute/50,UPDATE,79,1,2,11258,0,Tier/Base/4,TRUE,,,,,,,,,,
Passive/Absolute/51,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,51,Passive/Absolute/51,UPDATE,81,1,2,11687,0,,TRUE,,,,,,,,,,
Passive/Absolute/52,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,52,Passive/Absolute/52,UPDATE,83,1,2,12124,0,,TRUE,,,,,,,,,,
Passive/Absolute/53,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,53,Passive/Absolute/53,UPDATE,85,1,2,12569,0,,TRUE,,,,,,,,,,
Passive/Absolute/54,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,54,Passive/Absolute/54,UPDATE,87,1,3,13022,0,,TRUE,,,,,,,,,,
Passive/Absolute/55,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,55,Passive/Absolute/55,UPDATE,89,1,2,13483,0,,TRUE,,,,,,,,,,
Passive/Absolute/56,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,56,Passive/Absolute/56,UPDATE,91,1,2,13952,0,,TRUE,,,,,,,,,,
Passive/Absolute/57,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,57,Passive/Absolute/57,UPDATE,94,1,2,14429,0,,TRUE,,,,,,,,,,
Passive/Absolute/58,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,58,Passive/Absolute/58,UPDATE,96,1,3,14914,0,,TRUE,,,,,,,,,,
Passive/Absolute/59,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,59,Passive/Absolute/59,UPDATE,98,1,2,15407,0,,TRUE,,,,,,,,,,
Passive/Absolute/60,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,60,Passive/Absolute/60,UPDATE,101,1,2,15908,0,Tier/Base/5,TRUE,,,,,,,,,,
Passive/Absolute/61,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,61,Passive/Absolute/61,UPDATE,103,1,2,16417,0,,TRUE,,,,,,,,,,
Passive/Absolute/62,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,62,Passive/Absolute/62,UPDATE,105,1,3,16934,0,,TRUE,,,,,,,,,,
Passive/Absolute/63,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,63,Passive/Absolute/63,UPDATE,108,1,2,17459,0,,TRUE,,,,,,,,,,
Passive/Absolute/64,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,64,Passive/Absolute/64,UPDATE,110,1,3,17992,0,,TRUE,,,,,,,,,,
Passive/Absolute/65,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,65,Passive/Absolute/65,UPDATE,112,1,2,18533,0,,TRUE,,,,,,,,,,
Passive/Absolute/66,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,66,Passive/Absolute/66,UPDATE,115,1,2,19082,0,,TRUE,,,,,,,,,,
Passive/Absolute/67,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,67,Passive/Absolute/67,UPDATE,117,1,3,19639,0,,TRUE,,,,,,,,,,
Passive/Absolute/68,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,68,Passive/Absolute/68,UPDATE,120,1,2,20204,0,,TRUE,,,,,,,,,,
Passive/Absolute/69,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,69,Passive/Absolute/69,UPDATE,122,1,3,20777,0,,TRUE,,,,,,,,,,
Passive/Absolute/70,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,70,Passive/Absolute/70,UPDATE,125,1,2,21358,0,Tier/Base/6,TRUE,,,,,,,,,,
Passive/Absolute/71,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,71,Passive/Absolute/71,UPDATE,127,1,3,21947,0,,TRUE,,,,,,,,,,
Passive/Absolute/72,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,72,Passive/Absolute/72,UPDATE,130,1,3,22544,0,,TRUE,,,,,,,,,,
Passive/Absolute/73,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,73,Passive/Absolute/73,UPDATE,133,1,2,23149,0,,TRUE,,,,,,,,,,
Passive/Absolute/74,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,74,Passive/Absolute/74,UPDATE,135,1,3,23762,0,,TRUE,,,,,,,,,,
Passive/Absolute/75,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,75,Passive/Absolute/75,UPDATE,138,1,2,24383,0,,TRUE,,,,,,,,,,
Passive/Absolute/76,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,76,Passive/Absolute/76,UPDATE,141,1,3,25012,0,,TRUE,,,,,,,,,,
Passive/Absolute/77,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,77,Passive/Absolute/77,UPDATE,143,1,3,25649,0,,TRUE,,,,,,,,,,
Passive/Absolute/78,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,78,Passive/Absolute/78,UPDATE,146,1,2,26294,0,,TRUE,,,,,,,,,,
Passive/Absolute/79,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,79,Passive/Absolute/79,UPDATE,149,1,3,26947,0,,TRUE,,,,,,,,,,
Passive/Absolute/80,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,80,Passive/Absolute/80,UPDATE,151,1,3,27608,0,Tier/Base/7,TRUE,,,,,,,,,,
Passive/Absolute/81,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,81,Passive/Absolute/81,UPDATE,154,1,3,28277,0,,TRUE,,,,,,,,,,
Passive/Absolute/82,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,82,Passive/Absolute/82,UPDATE,157,1,2,28954,0,,TRUE,,,,,,,,,,
Passive/Absolute/83,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,83,Passive/Absolute/83,UPDATE,160,1,3,29639,0,,TRUE,,,,,,,,,,
Passive/Absolute/84,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,84,Passive/Absolute/84,UPDATE,163,1,3,30332,0,,TRUE,,,,,,,,,,
Passive/Absolute/85,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,85,Passive/Absolute/85,UPDATE,165,1,3,31033,0,,TRUE,,,,,,,,,,
Passive/Absolute/86,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,86,Passive/Absolute/86,UPDATE,168,1,3,31742,0,,TRUE,,,,,,,,,,
Passive/Absolute/87,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,87,Passive/Absolute/87,UPDATE,171,1,3,32459,0,,TRUE,,,,,,,,,,
Passive/Absolute/88,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,88,Passive/Absolute/88,UPDATE,174,1,3,33184,0,,TRUE,,,,,,,,,,
Passive/Absolute/89,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,89,Passive/Absolute/89,UPDATE,177,1,3,33917,0,,TRUE,,,,,,,,,,
Passive/Absolute/90,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,90,Passive/Absolute/90,UPDATE,180,1,3,34658,0,Tier/Base/8,TRUE,,,,,,,,,,
Passive/Absolute/91,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,91,Passive/Absolute/91,UPDATE,183,1,3,35407,0,,TRUE,,,,,,,,,,
Passive/Absolute/92,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,92,Passive/Absolute/92,UPDATE,186,1,3,36164,0,,TRUE,,,,,,,,,,
Passive/Absolute/93,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,93,Passive/Absolute/93,UPDATE,189,1,3,36929,0,,TRUE,,,,,,,,,,
Passive/Absolute/94,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,94,Passive/Absolute/94,UPDATE,192,1,3,37702,0,,TRUE,,,,,,,,,,
Passive/Absolute/95,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,95,Passive/Absolute/95,UPDATE,195,1,3,38483,0,,TRUE,,,,,,,,,,
Passive/Absolute/96,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,96,Passive/Absolute/96,UPDATE,198,1,3,39272,0,,TRUE,,,,,,,,,,
Passive/Absolute/97,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,97,Passive/Absolute/97,UPDATE,201,1,3,40069,0,,TRUE,,,,,,,,,,
Passive/Absolute/98,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,98,Passive/Absolute/98,UPDATE,205,1,3,40874,0,,TRUE,,,,,,,,,,
Passive/Absolute/99,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,99,Passive/Absolute/99,UPDATE,208,1,3,41687,0,,TRUE,,,,,,,,,,
Passive/Absolute/100,Zvětší pasivní příjem l dalších {diff}.,Increases passive income by {diff}.,Passive,Absolute,100,Passive/Absolute/100,UPDATE,211,1,4,42508,0,Tier/Base/9,TRUE,,,,,,,,,,
Active/Absolute/0,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,0,Active/Absolute/0,CLICK,3,1,3,10,0,,TRUE,,,,,,,,,,
Active/Absolute/1,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,1,Active/Absolute/1,CLICK,6,1,3,62,0,,TRUE,,,,,,,,,,
Active/Absolute/2,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,2,Active/Absolute/2,CLICK,9,1,3,116,0,,TRUE,,,,,,,,,,
Active/Absolute/3,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,3,Active/Absolute/3,CLICK,13,1,4,174,0,,TRUE,,,,,,,,,,
Active/Absolute/4,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,4,Active/Absolute/4,CLICK,17,1,4,234,0,,TRUE,,,,,,,,,,
Active/Absolute/5,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,5,Active/Absolute/5,CLICK,21,1,4,298,0,,TRUE,,,,,,,,,,
Active/Absolute/6,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,6,Active/Absolute/6,CLICK,25,1,4,364,0,,TRUE,,,,,,,,,,
Active/Absolute/7,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,7,Active/Absolute/7,CLICK,29,1,4,434,0,,TRUE,,,,,,,,,,
Active/Absolute/8,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,8,Active/Absolute/8,CLICK,33,1,5,506,0,,TRUE,,,,,,,,,,
Active/Absolute/9,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,9,Active/Absolute/9,CLICK,38,1,5,582,0,,TRUE,,,,,,,,,,
Active/Absolute/10,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,10,Active/Absolute/10,CLICK,43,1,5,660,0,Tier/Base/0,TRUE,,,,,,,,,,
Active/Absolute/11,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,11,Active/Absolute/11,CLICK,48,1,5,742,0,,TRUE,,,,,,,,,,
Active/Absolute/12,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,12,Active/Absolute/12,CLICK,53,1,5,826,0,,TRUE,,,,,,,,,,
Active/Absolute/13,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,13,Active/Absolute/13,CLICK,59,1,6,914,0,,TRUE,,,,,,,,,,
Active/Absolute/14,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,14,Active/Absolute/14,CLICK,65,1,6,1004,0,,TRUE,,,,,,,,,,
Active/Absolute/15,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,15,Active/Absolute/15,CLICK,71,1,6,1098,0,,TRUE,,,,,,,,,,
Active/Absolute/16,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,16,Active/Absolute/16,CLICK,77,1,6,1194,0,,TRUE,,,,,,,,,,
Active/Absolute/17,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,17,Active/Absolute/17,CLICK,83,1,6,1294,0,,TRUE,,,,,,,,,,
Active/Absolute/18,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,18,Active/Absolute/18,CLICK,89,1,7,1396,0,,TRUE,,,,,,,,,,
Active/Absolute/19,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,19,Active/Absolute/19,CLICK,96,1,7,1502,0,,TRUE,,,,,,,,,,
Active/Absolute/20,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,20,Active/Absolute/20,CLICK,103,1,7,1610,0,Tier/Base/1,TRUE,,,,,,,,,,
Active/Absolute/21,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,21,Active/Absolute/21,CLICK,110,1,7,1722,0,,TRUE,,,,,,,,,,
Active/Absolute/22,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,22,Active/Absolute/22,CLICK,117,1,7,1836,0,,TRUE,,,,,,,,,,
Active/Absolute/23,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,23,Active/Absolute/23,CLICK,125,1,8,1954,0,,TRUE,,,,,,,,,,
Active/Absolute/24,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,24,Active/Absolute/24,CLICK,133,1,8,2074,0,,TRUE,,,,,,,,,,
Active/Absolute/25,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,25,Active/Absolute/25,CLICK,141,1,8,2198,0,,TRUE,,,,,,,,,,
Active/Absolute/26,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,26,Active/Absolute/26,CLICK,149,1,8,2324,0,,TRUE,,,,,,,,,,
Active/Absolute/27,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,27,Active/Absolute/27,CLICK,157,1,8,2454,0,,TRUE,,,,,,,,,,
Active/Absolute/28,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,28,Active/Absolute/28,CLICK,165,1,9,2586,0,,TRUE,,,,,,,,,,
Active/Absolute/29,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,29,Active/Absolute/29,CLICK,174,1,9,2722,0,,TRUE,,,,,,,,,,
Active/Absolute/30,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,30,Active/Absolute/30,CLICK,183,1,9,2860,0,Tier/Base/2,TRUE,,,,,,,,,,
Active/Absolute/31,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,31,Active/Absolute/31,CLICK,192,1,9,3002,0,,TRUE,,,,,,,,,,
Active/Absolute/32,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,32,Active/Absolute/32,CLICK,201,1,9,3146,0,,TRUE,,,,,,,,,,
Active/Absolute/33,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,33,Active/Absolute/33,CLICK,211,1,10,3294,0,,TRUE,,,,,,,,,,
Active/Absolute/34,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,34,Active/Absolute/34,CLICK,221,1,10,3444,0,,TRUE,,,,,,,,,,
Active/Absolute/35,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,35,Active/Absolute/35,CLICK,231,1,10,3598,0,,TRUE,,,,,,,,,,
Active/Absolute/36,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,36,Active/Absolute/36,CLICK,241,1,10,3754,0,,TRUE,,,,,,,,,,
Active/Absolute/37,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,37,Active/Absolute/37,CLICK,251,1,10,3914,0,,TRUE,,,,,,,,,,
Active/Absolute/38,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,38,Active/Absolute/38,CLICK,261,1,11,4076,0,,TRUE,,,,,,,,,,
Active/Absolute/39,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,39,Active/Absolute/39,CLICK,272,1,11,4242,0,,TRUE,,,,,,,,,,
Active/Absolute/40,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,40,Active/Absolute/40,CLICK,283,1,11,4410,0,Tier/Base/3,TRUE,,,,,,,,,,
Active/Absolute/41,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,41,Active/Absolute/41,CLICK,294,1,11,4582,0,,TRUE,,,,,,,,,,
Active/Absolute/42,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,42,Active/Absolute/42,CLICK,305,1,11,4756,0,,TRUE,,,,,,,,,,
Active/Absolute/43,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,43,Active/Absolute/43,CLICK,317,1,12,4934,0,,TRUE,,,,,,,,,,
Active/Absolute/44,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,44,Active/Absolute/44,CLICK,329,1,12,5114,0,,TRUE,,,,,,,,,,
Active/Absolute/45,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,45,Active/Absolute/45,CLICK,341,1,12,5298,0,,TRUE,,,,,,,,,,
Active/Absolute/46,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,46,Active/Absolute/46,CLICK,353,1,12,5484,0,,TRUE,,,,,,,,,,
Active/Absolute/47,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,47,Active/Absolute/47,CLICK,365,1,12,5674,0,,TRUE,,,,,,,,,,
Active/Absolute/48,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,48,Active/Absolute/48,CLICK,377,1,13,5866,0,,TRUE,,,,,,,,,,
Active/Absolute/49,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,49,Active/Absolute/49,CLICK,390,1,13,6062,0,,TRUE,,,,,,,,,,
Active/Absolute/50,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,50,Active/Absolute/50,CLICK,403,1,13,6260,0,Tier/Base/4,TRUE,,,,,,,,,,
Active/Absolute/51,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,51,Active/Absolute/51,CLICK,416,1,13,6462,0,,TRUE,,,,,,,,,,
Active/Absolute/52,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,52,Active/Absolute/52,CLICK,429,1,13,6666,0,,TRUE,,,,,,,,,,
Active/Absolute/53,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,53,Active/Absolute/53,CLICK,443,1,14,6874,0,,TRUE,,,,,,,,,,
Active/Absolute/54,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,54,Active/Absolute/54,CLICK,457,1,14,7084,0,,TRUE,,,,,,,,,,
Active/Absolute/55,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,55,Active/Absolute/55,CLICK,471,1,14,7298,0,,TRUE,,,,,,,,,,
Active/Absolute/56,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,56,Active/Absolute/56,CLICK,485,1,14,7514,0,,TRUE,,,,,,,,,,
Active/Absolute/57,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,57,Active/Absolute/57,CLICK,499,1,14,7734,0,,TRUE,,,,,,,,,,
Active/Absolute/58,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,58,Active/Absolute/58,CLICK,513,1,15,7956,0,,TRUE,,,,,,,,,,
Active/Absolute/59,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,59,Active/Absolute/59,CLICK,528,1,15,8182,0,,TRUE,,,,,,,,,,
Active/Absolute/60,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,60,Active/Absolute/60,CLICK,543,1,15,8410,0,Tier/Base/5,TRUE,,,,,,,,,,
Active/Absolute/61,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,61,Active/Absolute/61,CLICK,558,1,15,8642,0,,TRUE,,,,,,,,,,
Active/Absolute/62,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,62,Active/Absolute/62,CLICK,573,1,15,8876,0,,TRUE,,,,,,,,,,
Active/Absolute/63,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,63,Active/Absolute/63,CLICK,589,1,16,9114,0,,TRUE,,,,,,,,,,
Active/Absolute/64,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,64,Active/Absolute/64,CLICK,605,1,16,9354,0,,TRUE,,,,,,,,,,
Active/Absolute/65,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,65,Active/Absolute/65,CLICK,621,1,16,9598,0,,TRUE,,,,,,,,,,
Active/Absolute/66,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,66,Active/Absolute/66,CLICK,637,1,16,9844,0,,TRUE,,,,,,,,,,
Active/Absolute/67,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,67,Active/Absolute/67,CLICK,653,1,16,10094,0,,TRUE,,,,,,,,,,
Active/Absolute/68,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,68,Active/Absolute/68,CLICK,669,1,17,10346,0,,TRUE,,,,,,,,,,
Active/Absolute/69,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,69,Active/Absolute/69,CLICK,686,1,17,10602,0,,TRUE,,,,,,,,,,
Active/Absolute/70,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,70,Active/Absolute/70,CLICK,703,1,17,10860,0,Tier/Base/6,TRUE,,,,,,,,,,
Active/Absolute/71,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,71,Active/Absolute/71,CLICK,720,1,17,11122,0,,TRUE,,,,,,,,,,
Active/Absolute/72,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,72,Active/Absolute/72,CLICK,737,1,17,11386,0,,TRUE,,,,,,,,,,
Active/Absolute/73,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,73,Active/Absolute/73,CLICK,755,1,18,11654,0,,TRUE,,,,,,,,,,
Active/Absolute/74,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,74,Active/Absolute/74,CLICK,773,1,18,11924,0,,TRUE,,,,,,,,,,
Active/Absolute/75,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,75,Active/Absolute/75,CLICK,791,1,18,12198,0,,TRUE,,,,,,,,,,
Active/Absolute/76,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,76,Active/Absolute/76,CLICK,809,1,18,12474,0,,TRUE,,,,,,,,,,
Active/Absolute/77,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,77,Active/Absolute/77,CLICK,827,1,18,12754,0,,TRUE,,,,,,,,,,
Active/Absolute/78,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,78,Active/Absolute/78,CLICK,845,1,19,13036,0,,TRUE,,,,,,,,,,
Active/Absolute/79,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,79,Active/Absolute/79,CLICK,864,1,19,13322,0,,TRUE,,,,,,,,,,
Active/Absolute/80,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,80,Active/Absolute/80,CLICK,883,1,19,13610,0,Tier/Base/7,TRUE,,,,,,,,,,
Active/Absolute/81,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,81,Active/Absolute/81,CLICK,902,1,19,13902,0,,TRUE,,,,,,,,,,
Active/Absolute/82,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,82,Active/Absolute/82,CLICK,921,1,19,14196,0,,TRUE,,,,,,,,,,
Active/Absolute/83,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,83,Active/Absolute/83,CLICK,941,1,20,14494,0,,TRUE,,,,,,,,,,
Active/Absolute/84,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,84,Active/Absolute/84,CLICK,961,1,20,14794,0,,TRUE,,,,,,,,,,
Active/Absolute/85,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,85,Active/Absolute/85,CLICK,981,1,20,15098,0,,TRUE,,,,,,,,,,
Active/Absolute/86,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,86,Active/Absolute/86,CLICK,1001,1,20,15404,0,,TRUE,,,,,,,,,,
Active/Absolute/87,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,87,Active/Absolute/87,CLICK,1021,1,20,15714,0,,TRUE,,,,,,,,,,
Active/Absolute/88,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,88,Active/Absolute/88,CLICK,1041,1,21,16026,0,,TRUE,,,,,,,,,,
Active/Absolute/89,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,89,Active/Absolute/89,CLICK,1062,1,21,16342,0,,TRUE,,,,,,,,,,
Active/Absolute/90,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,90,Active/Absolute/90,CLICK,1083,1,21,16660,0,Tier/Base/8,TRUE,,,,,,,,,,
Active/Absolute/91,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,91,Active/Absolute/91,CLICK,1104,1,21,16982,0,,TRUE,,,,,,,,,,
Active/Absolute/92,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,92,Active/Absolute/92,CLICK,1125,1,21,17306,0,,TRUE,,,,,,,,,,
Active/Absolute/93,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,93,Active/Absolute/93,CLICK,1147,1,22,17634,0,,TRUE,,,,,,,,,,
Active/Absolute/94,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,94,Active/Absolute/94,CLICK,1169,1,22,17964,0,,TRUE,,,,,,,,,,
Active/Absolute/95,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,95,Active/Absolute/95,CLICK,1191,1,22,18298,0,,TRUE,,,,,,,,,,
Active/Absolute/96,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,96,Active/Absolute/96,CLICK,1213,1,22,18634,0,,TRUE,,,,,,,,,,
Active/Absolute/97,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,97,Active/Absolute/97,CLICK,1235,1,22,18974,0,,TRUE,,,,,,,,,,
Active/Absolute/98,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,98,Active/Absolute/98,CLICK,1257,1,23,19316,0,,TRUE,,,,,,,,,,
Active/Absolute/99,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,99,Active/Absolute/99,CLICK,1280,1,23,19662,0,,TRUE,,,,,,,,,,
Active/Absolute/100,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,100,Active/Absolute/100,CLICK,1303,1,23,20010,0,,TRUE,,,,,,,,,,
Active/Absolute/101,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,101,Active/Absolute/101,CLICK,1326,1,23,20362,0,,TRUE,,,,,,,,,,
Active/Absolute/102,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,102,Active/Absolute/102,CLICK,1349,1,23,20716,0,,TRUE,,,,,,,,,,
Active/Absolute/103,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,103,Active/Absolute/103,CLICK,1373,1,24,21074,0,Tier/Base/9,TRUE,,,,,,,,,,
Active/Absolute/104,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,104,Active/Absolute/104,CLICK,1397,1,24,21434,0,,TRUE,,,,,,,,,,
Active/Absolute/105,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,105,Active/Absolute/105,CLICK,1421,1,24,21798,0,,TRUE,,,,,,,,,,
Active/Absolute/106,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,106,Active/Absolute/106,CLICK,1445,1,24,22164,0,,TRUE,,,,,,,,,,
Active/Absolute/107,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,107,Active/Absolute/107,CLICK,1469,1,24,22534,0,,TRUE,,,,,,,,,,
Active/Absolute/108,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,108,Active/Absolute/108,CLICK,1493,1,25,22906,0,,TRUE,,,,,,,,,,
Active/Absolute/109,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,109,Active/Absolute/109,CLICK,1518,1,25,23282,0,,TRUE,,,,,,,,,,
Active/Absolute/110,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,110,Active/Absolute/110,CLICK,1543,1,25,23660,0,,TRUE,,,,,,,,,,
Active/Absolute/111,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,111,Active/Absolute/111,CLICK,1568,1,25,24042,0,,TRUE,,,,,,,,,,
Active/Absolute/112,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,112,Active/Absolute/112,CLICK,1593,1,25,24426,0,,TRUE,,,,,,,,,,
Active/Absolute/113,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,113,Active/Absolute/113,CLICK,1619,1,26,24814,0,,TRUE,,,,,,,,,,
Active/Absolute/114,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,114,Active/Absolute/114,CLICK,1645,1,26,25204,0,,TRUE,,,,,,,,,,
Active/Absolute/115,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,115,Active/Absolute/115,CLICK,1671,1,26,25598,0,,TRUE,,,,,,,,,,
Active/Absolute/116,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,116,Active/Absolute/116,CLICK,1697,1,26,25994,0,,TRUE,,,,,,,,,,
Active/Absolute/117,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,117,Active/Absolute/117,CLICK,1723,1,26,26394,0,,TRUE,,,,,,,,,,
Active/Absolute/118,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,118,Active/Absolute/118,CLICK,1749,1,27,26796,0,,TRUE,,,,,,,,,,
Active/Absolute/119,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,119,Active/Absolute/119,CLICK,1776,1,27,27202,0,,TRUE,,,,,,,,,,
Active/Absolute/120,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,120,Active/Absolute/120,CLICK,1803,1,27,27610,0,,TRUE,,,,,,,,,,
Active/Absolute/121,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,121,Active/Absolute/121,CLICK,1830,1,27,28022,0,,TRUE,,,,,,,,,,
Active/Absolute/122,Zvětší příjem za každý klik o {diff}.,Increases click income by {diff}.,Active,Absolute,122,Active/Absolute/122,CLICK,1857,1,27,28436,0,,TRUE,,,,,,,,,,
Tier/Base/0,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,0,Tier/Base/0,NONE,,,,1000,,,TRUE,,,,,,,,,,
Tier/Base/1,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,1,Tier/Base/1,NONE,,,,3180,,,TRUE,,,,,,,,,,
Tier/Base/2,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,2,Tier/Base/2,NONE,,,,5720,,,TRUE,,,,,,,,,,
Tier/Base/3,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,3,Tier/Base/3,NONE,,,,8620,,,TRUE,,,,,,,,,,
Tier/Base/4,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,4,Tier/Base/4,NONE,,,,11880,,,TRUE,,,,,,,,,,
Tier/Base/5,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,5,Tier/Base/5,NONE,,,,15500,,,TRUE,,,,,,,,,,
Tier/Base/6,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,6,Tier/Base/6,NONE,,,,19480,,,TRUE,,,,,,,,,,
Tier/Base/7,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,7,Tier/Base/7,NONE,,,,23820,,,TRUE,,,,,,,,,,
Tier/Base/8,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,8,Tier/Base/8,NONE,,,,28520,,,TRUE,,,,,,,,,,
Tier/Base/9,Odemyká další vylepšení,Unlocks further upgrades,Tier,Base,9,Tier/Base/9,NONE,,,,33580,,,TRUE,,,,,,,,,,
Notification/Title/0,Oznámí když je číslo na maximu,Notifies when the number is at max,Notification,Title,0,Notification/Title/0,NONE,,,,1000,,Tier/Base/0,TRUE,Notify when max,,,,,,,,,
Notification/Title/1,Ukazuje průběh v titulku,Shows current value in the title,Notification,Title,1,Notification/Title/1,NONE,,,,2500,,Tier/Base/1,TRUE,Notify in numbers,,,,,,,,,
Notification/Title/2,,,Notification,Title,2,Notification/Title/2,NONE,,,,5000,,Tier/Base/2,FALSE,Notify in percent,,,,,,,,,
CurrentMax/Absolute/0,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,0,CurrentMax/Absolute/0,NONE,1000,0,1000,400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/1,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,1,CurrentMax/Absolute/1,NONE,2200,0,1200,1200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/2,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,2,CurrentMax/Absolute/2,NONE,3800,0,1600,2000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/3,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,3,CurrentMax/Absolute/3,NONE,5800,0,2000,2800,0,Tier/Base/0,TRUE,,,,,,,,,,
CurrentMax/Absolute/4,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,4,CurrentMax/Absolute/4,NONE,8200,0,2400,3600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/5,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,5,CurrentMax/Absolute/5,NONE,11000,0,2800,4400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/6,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,6,CurrentMax/Absolute/6,NONE,14200,0,3200,5200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/7,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,7,CurrentMax/Absolute/7,NONE,17800,0,3600,6000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/8,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,8,CurrentMax/Absolute/8,NONE,21800,0,4000,6800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/9,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,9,CurrentMax/Absolute/9,NONE,26200,0,4400,7600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/10,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,10,CurrentMax/Absolute/10,NONE,31000,0,4800,8400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/11,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,11,CurrentMax/Absolute/11,NONE,36200,0,5200,9200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/12,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,12,CurrentMax/Absolute/12,NONE,41800,0,5600,10000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/13,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,13,CurrentMax/Absolute/13,NONE,47800,0,6000,10800,0,Tier/Base/1,TRUE,,,,,,,,,,
CurrentMax/Absolute/14,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,14,CurrentMax/Absolute/14,NONE,54200,0,6400,11600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/15,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,15,CurrentMax/Absolute/15,NONE,61000,0,6800,12400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/16,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,16,CurrentMax/Absolute/16,NONE,68200,0,7200,13200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/17,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,17,CurrentMax/Absolute/17,NONE,75800,0,7600,14000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/18,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,18,CurrentMax/Absolute/18,NONE,83800,0,8000,14800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/19,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,19,CurrentMax/Absolute/19,NONE,92200,0,8400,15600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/20,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,20,CurrentMax/Absolute/20,NONE,101000,0,8800,16400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/21,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,21,CurrentMax/Absolute/21,NONE,110200,0,9200,17200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/22,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,22,CurrentMax/Absolute/22,NONE,119800,0,9600,18000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/23,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,23,CurrentMax/Absolute/23,NONE,129800,0,10000,18800,0,Tier/Base/2,TRUE,,,,,,,,,,
CurrentMax/Absolute/24,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,24,CurrentMax/Absolute/24,NONE,140200,0,10400,19600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/25,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,25,CurrentMax/Absolute/25,NONE,151000,0,10800,20400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/26,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,26,CurrentMax/Absolute/26,NONE,162200,0,11200,21200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/27,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,27,CurrentMax/Absolute/27,NONE,173800,0,11600,22000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/28,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,28,CurrentMax/Absolute/28,NONE,185800,0,12000,22800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/29,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,29,CurrentMax/Absolute/29,NONE,198200,0,12400,23600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/30,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,30,CurrentMax/Absolute/30,NONE,211000,0,12800,24400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/31,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,31,CurrentMax/Absolute/31,NONE,224200,0,13200,25200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/32,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,32,CurrentMax/Absolute/32,NONE,237800,0,13600,26000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/33,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,33,CurrentMax/Absolute/33,NONE,251800,0,14000,26800,0,Tier/Base/3,TRUE,,,,,,,,,,
CurrentMax/Absolute/34,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,34,CurrentMax/Absolute/34,NONE,266200,0,14400,27600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/35,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,35,CurrentMax/Absolute/35,NONE,281000,0,14800,28400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/36,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,36,CurrentMax/Absolute/36,NONE,296200,0,15200,29200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/37,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,37,CurrentMax/Absolute/37,NONE,311800,0,15600,30000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/38,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,38,CurrentMax/Absolute/38,NONE,327800,0,16000,30800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/39,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,39,CurrentMax/Absolute/39,NONE,344200,0,16400,31600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/40,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,40,CurrentMax/Absolute/40,NONE,361000,0,16800,32400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/41,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,41,CurrentMax/Absolute/41,NONE,378200,0,17200,33200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/42,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,42,CurrentMax/Absolute/42,NONE,395800,0,17600,34000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/43,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,43,CurrentMax/Absolute/43,NONE,413800,0,18000,34800,0,Tier/Base/4,TRUE,,,,,,,,,,
CurrentMax/Absolute/44,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,44,CurrentMax/Absolute/44,NONE,432200,0,18400,35600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/45,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,45,CurrentMax/Absolute/45,NONE,451000,0,18800,36400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/46,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,46,CurrentMax/Absolute/46,NONE,470200,0,19200,37200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/47,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,47,CurrentMax/Absolute/47,NONE,489800,0,19600,38000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/48,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,48,CurrentMax/Absolute/48,NONE,509800,0,20000,38800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/49,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,49,CurrentMax/Absolute/49,NONE,530200,0,20400,39600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/50,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,50,CurrentMax/Absolute/50,NONE,551000,0,20800,40400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/51,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,51,CurrentMax/Absolute/51,NONE,572200,0,21200,41200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/52,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,52,CurrentMax/Absolute/52,NONE,593800,0,21600,42000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/53,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,53,CurrentMax/Absolute/53,NONE,615800,0,22000,42800,0,Tier/Base/5,TRUE,,,,,,,,,,
CurrentMax/Absolute/54,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,54,CurrentMax/Absolute/54,NONE,638200,0,22400,43600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/55,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,55,CurrentMax/Absolute/55,NONE,661000,0,22800,44400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/56,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,56,CurrentMax/Absolute/56,NONE,684200,0,23200,45200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/57,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,57,CurrentMax/Absolute/57,NONE,707800,0,23600,46000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/58,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,58,CurrentMax/Absolute/58,NONE,731800,0,24000,46800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/59,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,59,CurrentMax/Absolute/59,NONE,756200,0,24400,47600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/60,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,60,CurrentMax/Absolute/60,NONE,781000,0,24800,48400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/61,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,61,CurrentMax/Absolute/61,NONE,806200,0,25200,49200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/62,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,62,CurrentMax/Absolute/62,NONE,831800,0,25600,50000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/63,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,63,CurrentMax/Absolute/63,NONE,857800,0,26000,50800,0,Tier/Base/6,TRUE,,,,,,,,,,
CurrentMax/Absolute/64,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,64,CurrentMax/Absolute/64,NONE,884200,0,26400,51600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/65,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,65,CurrentMax/Absolute/65,NONE,911000,0,26800,52400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/66,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,66,CurrentMax/Absolute/66,NONE,938200,0,27200,53200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/67,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,67,CurrentMax/Absolute/67,NONE,965800,0,27600,54000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/68,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,68,CurrentMax/Absolute/68,NONE,993800,0,28000,54800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/69,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,69,CurrentMax/Absolute/69,NONE,1022200,0,28400,55600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/70,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,70,CurrentMax/Absolute/70,NONE,1051000,0,28800,56400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/71,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,71,CurrentMax/Absolute/71,NONE,1080200,0,29200,57200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/72,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,72,CurrentMax/Absolute/72,NONE,1109800,0,29600,58000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/73,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,73,CurrentMax/Absolute/73,NONE,1139800,0,30000,58800,0,Tier/Base/7,TRUE,,,,,,,,,,
CurrentMax/Absolute/74,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,74,CurrentMax/Absolute/74,NONE,1170200,0,30400,59600,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/75,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,75,CurrentMax/Absolute/75,NONE,1201000,0,30800,60400,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/76,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,76,CurrentMax/Absolute/76,NONE,1232200,0,31200,61200,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/77,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,77,CurrentMax/Absolute/77,NONE,1263800,0,31600,62000,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/78,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,78,CurrentMax/Absolute/78,NONE,1295800,0,32000,62800,0,,TRUE,,,,,,,,,,
CurrentMax/Absolute/79,Zvětší horní limit o {diff}.,Increases max number by {diff}.,CurrentMax,Absolute,79,CurrentMax/Absolute/79,NONE,1328200,0,32400,63600,0,,TRUE,,,,,,,,,,
UI/BiggerButton/0,Snadnější klikání!,Easier clicking!,UI,BiggerButton,0,UI/BiggerButton/0,NONE,,,,1000,,,TRUE,,,,,,,,,,
UI/BiggerButton/1,Snadnější klikání!,Easier clicking!,UI,BiggerButton,1,UI/BiggerButton/1,NONE,,,,2000,,,TRUE,,,,,,,,,,
UI/BiggerButton/2,Snadnější klikání!,Easier clicking!,UI,BiggerButton,2,UI/BiggerButton/2,NONE,,,,3000,,,TRUE,,,,,,,,,,
Refund/Upgrade/0,Za každý upgrade vrátí {diff} zpět.,Earns {diff} back for every upgrade.,Refund,Upgrade,0,Refund/Upgrade/0,UPGRADE,10,,10,1000,,,TRUE,,,,,,,,,,
Refund/Upgrade/1,Za každý upgrade vrátí {diff} zpět.,Earns {diff} back for every upgrade.,Refund,Upgrade,1,Refund/Upgrade/1,UPGRADE,15,,5,2000,,,TRUE,,,,,,,,,,
Refund/Upgrade/2,Za každý upgrade vrátí {diff} zpět.,Earns {diff} back for every upgrade.,Refund,Upgrade,2,Refund/Upgrade/2,UPGRADE,20,,5,5000,,,TRUE,,,,,,,,,,
Refund/Upgrade/3,Za každý upgrade vrátí {diff} zpět.,Earns {diff} back for every upgrade.,Refund,Upgrade,3,Refund/Upgrade/3,UPGRADE,25,,5,8000,,,TRUE,,,,,,,,,,
Refund/Upgrade/4,Za každý upgrade vrátí {diff} zpět.,Earns {diff} back for every upgrade.,Refund,Upgrade,4,Refund/Upgrade/4,UPGRADE,30,,5,11000,,,TRUE,,,,,,,,,,
Refund/Upgrade/5,Za každý upgrade vrátí {diff} zpět.,Earns {diff} back for every upgrade.,Refund,Upgrade,5,Refund/Upgrade/5,UPGRADE,35,,5,14000,,,TRUE,,,,,,,,,,
Auto/Upgrade/0,Automaticky upgraduje nejlevnější možný upgrade každých {absolute} sekund.,Autoupgrades the cheapest upgrade every {absolute} seconds.,Auto,Upgrade,0,Auto/Upgrade/0,UPDATE,600,0,600,1000,,,TRUE,,,,,,,,,,
Auto/Upgrade/1,Automaticky upgraduje nejlevnější možný upgrade každých {absolute} sekund.,Autoupgrades the cheapest upgrade every {absolute} seconds.,Auto,Upgrade,1,Auto/Upgrade/1,UPDATE,420,0,180,2000,,,TRUE,,,,,,,,,,
Auto/Upgrade/2,Automaticky upgraduje nejlevnější možný upgrade každých {absolute} sekund.,Autoupgrades the cheapest upgrade every {absolute} seconds.,Auto,Upgrade,2,Auto/Upgrade/2,UPDATE,270,0,150,5000,,,TRUE,,,,,,,,,,
Auto/Upgrade/3,Automaticky upgraduje nejlevnější možný upgrade každých {absolute} sekund.,Autoupgrades the cheapest upgrade every {absolute} seconds.,Auto,Upgrade,3,Auto/Upgrade/3,UPDATE,180,0,90,8000,,,TRUE,,,,,,,,,,
Auto/Upgrade/4,Automaticky upgraduje nejlevnější možný upgrade každých {absolute} sekund.,Autoupgrades the cheapest upgrade every {absolute} seconds.,Auto,Upgrade,4,Auto/Upgrade/4,UPDATE,120,0,60,11000,,,TRUE,,,,,,,,,,
Passive/Interest/0,Každých {absolute} sekund připočítá {relative} úrok.,Adds {relative} interest every {absolute} seconds.,Passive,Interest,0,Passive/Interest/0,UPDATE,30,0.01,30,100,,,FALSE,,,,,,,,,,
Passive/Interest/1,Každých {absolute} sekund připočítá {relative} úrok.,Adds {relative} interest every {absolute} seconds.,Passive,Interest,1,Passive/Interest/1,UPDATE,10,0.02,-20,100,,,FALSE,,,,,,,,,,"""                
                