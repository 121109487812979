package eu.karelhovorka.numbers

import kotlin.math.floor
import kotlin.math.log
import kotlin.math.min
import kotlin.math.pow

typealias HugeNumber = Long

val ZERO_HUGE_NUMBER: HugeNumber = 0.toHugeNumber()

fun HugeNumber.signSymbol(): String {
    return if (this > 0) {
        "+"
    } else if (this < 0) {
        "-"
    } else {
        ""
    }
}

fun Int.signSymbol(): String {
    return if (this > 0) {
        "+"
    } else if (this < 0) {
        "-"
    } else {
        ""
    }
}

fun String.toHugeNumber(): HugeNumber {
    return toLong()
}

fun String.toHugeNumberOrNull(): HugeNumber? {
    return toLongOrNull()
}

fun Double.toHugeNumber(): HugeNumber {
    return toLong()
}

fun Int.toHugeNumber(): HugeNumber {
    return toLong()
}


fun HugeNumber.short(minimum: Int = 1000): String {
    if (this < minimum) {
        return toString()
    }
    val log = log(this.toDouble(), 1000.0)
    val intLog = floor(log).toInt()
    val maxExp = min(intLog, expToSign.keys.max())
    val num = (toDouble() / (1000.0.pow(maxExp)))
    val strNumber = num.toString()
    val full = if (strNumber.contains(".")) {
        strNumber.split(".").first().toInt()
    } else {
        strNumber.toInt()
    }
    val decimal = if (strNumber.contains(".")) {
        strNumber.split(".").last().first().toString().toInt()
    } else {
        0
    }
    return if (decimal > 0) {
        "$full.$decimal${expToSign[maxExp]}"
    } else {
        "$full${expToSign[maxExp]}"
    }
}
