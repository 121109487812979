package eu.karelhovorka.numbers

import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.action.TimedAction
import eu.karelhovorka.numbers.multiplayer.GameId
import eu.karelhovorka.numbers.multiplayer.Player
import eu.karelhovorka.numbers.multiplayer.generateRandomString
import eu.karelhovorka.numbers.upgradable.GameType
import eu.karelhovorka.numbers.upgradable.UnLeveledType
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class State(
    val score: Score = 0.toScore(),
    val lastActionsByName: Map<String, TimedAction> = emptyMap(),
    val initialIncomesByAction: Map<Actions, Income> = emptyMap(),
    val currentIncomesByAction: Map<Actions, Income>,
    val currentMoney: Score,
    val currentMax: Score,
    val upgrades: List<GameType> = emptyList(),
    val gameId: GameId = generateRandomString(30),
    val start: Instant,
    val player: Player,
    val version: Int,
) {

    fun upgradeLevelOf(unLeveledType: UnLeveledType): Int? {
        return upgrades.filter { it.toUnLeveled() == unLeveledType }.maxByOrNull { it.level }?.level
    }

    companion object {
        fun fromCsv(map: Map<String, String>): State {
            return State(
                score = map.getValue("score").toScore(),
                initialIncomesByAction = incomesFromCsv(map),
                currentIncomesByAction = emptyMap(),
                upgrades = GameType.parseMany(map["upgrades"]),
                currentMax = map["currentMax"]?.toHugeNumberOrNull()?.toScore() ?: 1000.toScore(),
                currentMoney = map.getValue("score").toScore(),
                version = map["version"]?.toIntOrNull() ?: 0,
                start = Clock.System.now(),
                player = Player("random csv player!")
            )
        }

        fun incomesFromCsv(map: Map<String, String>): Map<Actions, Income> {
            val result = mutableMapOf<Actions, Income>()
            if ("updateIncome" in map) {
                result[Actions.UPDATE] = Income(absolute = map.getValue("updateIncome").toHugeNumber())
            }
            if ("clickIncome" in map) {
                result[Actions.CLICK] = Income(absolute = map.getValue("clickIncome").toHugeNumber())
            }

            return result
        }

        val DEFAULT = State(
            currentMoney = 0.toHugeNumber(),
            currentMax = 1000.toHugeNumber(),
            start = Clock.System.now(),
            player = Player("default player!"),
            version = 0,
            currentIncomesByAction = emptyMap(),
        )

    }
}
