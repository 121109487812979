package eu.karelhovorka.numbers

import eu.karelhovorka.numbers.action.ActionListener
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.passive.IncomeCalculator
import eu.karelhovorka.numbers.upgradable.upgrade.AbsoluteIncomeUpgrade

class ActionBasedIncome(
    initialAbsoluteIncomeUpgrades: List<AbsoluteIncomeUpgrade> = emptyList(),
) : ActionListener {

    //private val incomeUpgrades: MutableList<IncomeCalculator> = initialIncomeUpgrades.toMutableList()
    //private val megaUpgrades: MutableList<MetaUpgrade> = mutableListOf()

    private val incomeCalculators: MutableList<IncomeCalculator> = mutableListOf()

    private val incomeUpgrades = initialAbsoluteIncomeUpgrades.toMutableList()

    private fun update(list: List<AbsoluteIncomeUpgrade>) {
        incomeCalculators.clear()
        incomeCalculators.addAll(list.groupBy { it.id.toUnLeveled() }.values.map {
            it.maxBy { it.id.level }.actionBasedIncomes
        })
    }

    fun upgrade(upgrade: AbsoluteIncomeUpgrade) {
        incomeUpgrades.add(upgrade)
        update(incomeUpgrades)
    }

    fun calculateIncome(income: Income): Income {
        var result = income
        currentUpgrades().forEach {
            result = it.calculate(result)
        }
        return result
    }

    private fun currentUpgrades(): List<IncomeCalculator> {
        return incomeCalculators.filter { it.active() }
    }

    companion object {
        fun empty(): ActionBasedIncome {
            return ActionBasedIncome()
        }
    }

    override fun onAction(action: Actions, state: State) {
        incomeCalculators.filterIsInstance<ActionListener>().forEach { it.onAction(action, state) }
    }
}
