package eu.karelhovorka.numbers.exceptions

import eu.karelhovorka.numbers.Score
import eu.karelhovorka.numbers.i18n.i18n
import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade
import eu.karelhovorka.numbers.upgradable.upgrade.toI18NMap

class NotEnoughMoneyForUpgradeException(
    val upgrade: Upgrade,
    val currentMoney: Score,
) : RuntimeException(
    "not-enough-money-for-upgrade".i18n(
        mapOf(
            "currentMoney" to currentMoney.toString(),
        ) + upgrade.toI18NMap()
    )
)
