package eu.karelhovorka.numbers.i18n


fun parseI18N(list: List<Map<String, String>>) {
    list.forEach { map ->
        for (lang in map.keys.filter { it != "key" }) {
            addTranslation(key = map.getValue("key"), lang = lang, value = map.getValue(lang))
        }
    }
}
