package eu.karelhovorka.numbers.action

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class TimedAction(
    val action: Actions,
    val timestamp: Instant,
)
