package eu.karelhovorka.numbers.tools

import org.w3c.dom.*

fun <T : ParentNode> ItemArrayLike<Node>.asElementList(): List<T> = object : AbstractList<T>() {
    override val size: Int get() = this@asElementList.length

    override fun get(index: Int): T = when (index) {
        in 0..lastIndex -> this@asElementList.item(index).unsafeCast<T>()
        else -> throw IndexOutOfBoundsException("index $index is not in range [0..$lastIndex]")
    }
}

inline fun <reified E : Element> Document.queryHtmlSelectorAll(selectors: String): List<E> {
    return this.querySelectorAll(selectors = selectors).asElementList()
}

inline fun <reified E : Element> Document.queryHtmlSelector(selectors: String): E {
    return this.querySelector(selectors = selectors) as E
}

inline fun <reified E : HTMLElement> Document.getHtmlElementById(id: String): E {
    return this.getHtmlElementByIdOrNull(id) ?: error("Could not find element by id $id")
}

inline fun <reified E : HTMLElement> Document.getHtmlElementByIdOrNull(id: String): E? {
    return this.getElementById(id) as E?
}

inline fun <reified E : HTMLElement> Document.ghebID(id: String) = this.getHtmlElementById<E>(id)


inline fun <reified E : HTMLElement> Document.createHtmlElement(localName: String): E {
    return this.createElement(localName) as E
}