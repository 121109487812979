package eu.karelhovorka.numbers.multiplayer

import eu.karelhovorka.numbers.Game
import kotlinx.serialization.Serializable

@Serializable
data class ScoreSubmission(
    val gameScore: GameScore,
    val player: Player,
)


fun Game.toScoreSubmission(): ScoreSubmission {
    val state = state()
    return ScoreSubmission(
        player = state.player,
        gameScore = GameScore.create(state)
    )
}
