package eu.karelhovorka.numbers.upgradable.upgrade

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.Income
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.passive.AbsoluteRelativeUpgradableCalculator
import eu.karelhovorka.numbers.passive.IncomeCalculator
import eu.karelhovorka.numbers.toHugeNumber
import eu.karelhovorka.numbers.upgradable.*

data class AbsoluteIncomeUpgrade(
    override val id: GameType,
    override val actions: Actions,
    override val requires: Set<GameType>,
    override val cost: HugeNumber,
    override val order: Int,
    override val description: String = generateDescription(id, requires, cost),
    val absoluteIncome: HugeNumber,
    override val diffValue: Number,
) : GameObject, ValueUpgrade {

    val actionBasedIncomes: IncomeCalculator = AbsoluteRelativeUpgradableCalculator(
        income = Income(absolute = absoluteIncome),
        order = 0,
    )


    companion object {
        fun fromCSV(map: List<Map<String, String>>): List<AbsoluteIncomeUpgrade> {
            return map.map { fromSingleUpgrade(BasicUpgradeData.fromCsvLine(it)) }
        }

        fun fromSingleUpgrade(basicUpgradeData: BasicUpgradeData): AbsoluteIncomeUpgrade {
            return AbsoluteIncomeUpgrade(
                actions = basicUpgradeData.actions,
                id = basicUpgradeData.gameType,
                requires = basicUpgradeData.requiredIds,
                cost = basicUpgradeData.cost,
                order = basicUpgradeData.order,
                absoluteIncome = basicUpgradeData.income.absolute,
                diffValue = basicUpgradeData.absoluteDifference,
            )
        }

        fun empty(): AbsoluteIncomeUpgrade {
            return AbsoluteIncomeUpgrade(
                id = GameType.empty(),
                actions = Actions.NONE,
                requires = emptySet(),
                cost = 0,
                order = 0,
                absoluteIncome = 0.toHugeNumber(),
                diffValue = 0.toHugeNumber(),
            )
        }
    }

    override val upgradedValue: Number
        get() = absoluteIncome
}
