package eu.karelhovorka.numbers.upgradable

import eu.karelhovorka.numbers.i18n.DataTranslatable
import eu.karelhovorka.numbers.i18n.Translatable
import eu.karelhovorka.numbers.upgradable.UnLeveledType.Companion.NOTIFY_UNLEVELED
import kotlinx.serialization.Serializable

fun UnLeveledType.asTranslatable(): Translatable {
    return DataTranslatable(translateKey = "upgrade-${this@asTranslatable}")
}

fun GameType.descriptionLangKey(): String {
    return "description-${toString()}"
}

@Serializable
data class GameType(
    override val typeId: String,
    override val subtypeId: String,
    val level: Int = 0,
): Id {

    constructor(unLeveledType: UnLeveledType, level: Int = 0) : this(
        typeId = unLeveledType.typeId,
        subtypeId = unLeveledType.subtypeId,
        level = level
    )

    fun toUnLeveled(): UnLeveledType {
        return UnLeveledType(typeId = typeId, subtypeId = subtypeId)
    }

    override fun toString(): String {
        return "$typeId/$subtypeId/$level"
    }

    fun css(): String {
        return toString().replace("/", "-")
    }

    private operator fun plus(next: Int): GameType {
        check(next + level >= 0) {
            "Cannot get below zero with upgrades"
        }
        return copy(level = this.level + next)
    }

    fun reduceLevel(by: Int = 1): GameType {
        check(level >= by) { "cannot get below zero with upgrades for ${toString()}" }
        return this + (-by)
    }

    fun increaseLevel(by: Int = 1): GameType {
        return this + (by)
    }

    companion object {

        val NOTIFY = (0..2).map {
            GameType(NOTIFY_UNLEVELED, it)
        }

        fun empty(): GameType {
            return GameType("", "")
        }

        fun parse(text: String): GameType {
            val s = text.split("/")
            check(s.size == 3) { "Invalid id $text" }
            return GameType(
                s.first(),
                s[1],
                s[2].toInt(),
            )
        }

        fun parseMany(text: String?): List<GameType> {
            if (text == null || text.isBlank()) {
                return emptyList()
            }
            return text.split(",").map { parse(it) }
        }
    }
}
