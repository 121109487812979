package eu.karelhovorka.numbers.passive

import eu.karelhovorka.numbers.Income

class AbsoluteRelativeUpgradableCalculator(
    val income: Income,
    override val order: Int,
) : IncomeCalculator {

    override fun toString(): String {
        return income.toString()
    }

    override fun calculate(income: Income): Income {
        return income + this.income
    }

    override fun active(): Boolean {
        return true
    }


    override fun compareTo(other: IncomeCalculator): Int {
        return order.compareTo(other.order)
    }
}
