package eu.karelhovorka.numbers

import eu.karelhovorka.numbers.i18n.i18n
import kotlinx.browser.document
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.ItemArrayLike
import org.w3c.dom.asList


fun <T> ItemArrayLike<T>.asHtmlList(): List<HTMLElement> {
    return asList().filterIsInstance<HTMLElement>()
}

fun Element.hide() = with(this as HTMLElement) {
    setAttribute("data-old-display", style.display)
    style.display = "none"
}

fun Element.show() = with(this as HTMLElement) {
    style.display = getAttribute("data-old-display") ?: "block"
}

fun processI18N() {
    document.querySelectorAll(".static-i18n").asHtmlList().forEach {
        it.innerText = it.innerText.i18n()
    }
}

fun enableTooltips() {
    js(
        """
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
    """
    )
}