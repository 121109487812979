package eu.karelhovorka.numbers.config

import eu.karelhovorka.numbers.Game
import eu.karelhovorka.numbers.State
import eu.karelhovorka.numbers.action.ActionListener
import eu.karelhovorka.numbers.upgradable.UpgradeRepository

class LoadGameFactory(val upgradeRepository: UpgradeRepository, val state: State) : GameFactory {
    override fun create(actionListener: ActionListener): Game {
        return Game(
            initialState = state,
            repository = upgradeRepository,
            listener = actionListener,
        )
    }
}
