package eu.karelhovorka.numbers.upgradable

import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade

class MemoryUpgradeRepository(
    private val map: Map<GameType, Upgrade>
) : UpgradeRepository {
    override fun get(gameType: GameType): Upgrade {
        return map.getValue(gameType)
    }

    override fun all(): List<Upgrade> {
        return map.values.toList()
    }
}
