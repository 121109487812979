package eu.karelhovorka.numbers.upgradable

import kotlinx.serialization.Serializable

@Serializable
data class UnLeveledType(
    override val typeId: String,
    override val subtypeId: String,
) : Id {
    override fun toString(): String {
        return "$typeId/$subtypeId"
    }

    fun asLevel(level: Int): GameType {
        return GameType(
            this, level
        )
    }

    companion object {
        val BIGGER_BUTTON_UNLEVELED = parse("UI/BiggerButton")
        val NOTIFY_UNLEVELED = parse("Notification/Title")
        val CURRENT_MAX_ABSOLUTE = parse("CurrentMax/Absolute")
        val PASSIVE_ABSOLUTE = parse("Passive/Absolute")
        val ACTIVE_ABSOLUTE = parse("Active/Absolute")
        val AUTO_UPGRADE = parse("Auto/Upgrade")
        fun parse(text: String): UnLeveledType {
            val s = text.split("/")
            check(s.size == 2) { "Invalid id $text" }
            return UnLeveledType(
                s.first(),
                s.last(),
            )
        }
    }
}
