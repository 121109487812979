package eu.karelhovorka.numbers

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

interface Loop<T: Updatable> {
    val active: Boolean
    fun start()
    fun stop()
    val updatable: T
    val updateDelay: Duration
}

class SimpleLoop<T: Updatable>(
    override val updatable: T,
    override val updateDelay: Duration = 1.seconds,
    override val coroutineContext: CoroutineContext = Job(),
) : CoroutineScope, Loop<T> {

    override var active: Boolean = true
        private set

    override fun start() {
        active = true
        launch {
            while (active) {
                delay(updateDelay)
                if (active) {
                    updatable.update(updateDelay)
                }
            }
        }
    }

    override fun stop() {
        active = false
    }

}
