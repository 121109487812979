package eu.karelhovorka.numbers.multiplayer.client

import eu.karelhovorka.numbers.multiplayer.BoardItem
import eu.karelhovorka.numbers.multiplayer.ScoreService
import eu.karelhovorka.numbers.multiplayer.ScoreSubmission
import eu.karelhovorka.numbers.multiplayer.ScoreSubmissionResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

class ScoreServiceClient(
    val baseUrl: String = API,
    val client: HttpClient = HttpClient() {
        install(ContentNegotiation) {
            json(
                Json {
                    prettyPrint = true
                    isLenient = true
                    ignoreUnknownKeys = true
                },
            )
        }
    }
) : ScoreService {


    private suspend fun post(path: String, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
        return client.post(baseUrl + path, block)
    }

    private suspend fun get(path: String, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
        return client.get(baseUrl + path, block)
    }

    override suspend fun submit(scoreSubmission: ScoreSubmission): ScoreSubmissionResponse {
        return post(SUBMIT) {
            contentType(ContentType.Application.Json)
            setBody(scoreSubmission)
        }.body<ScoreSubmissionResponse>()
    }

    override suspend fun top(): List<BoardItem> {
        return get(TOP).body<List<BoardItem>>().subList(0, 10)
    }

    companion object {
        val SUBMIT = "/submit"
        val TOP = "/top"
        val API = "/api"
    }
}
