package eu.karelhovorka.numbers.upgradable

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.upgradable.upgrade.CurrentMaxUpgrade

class CurrentMaxCalculator {

    fun calculate(initialCurrentMax: HugeNumber, upgrades: List<CurrentMaxUpgrade>): HugeNumber {
        var currentMax = initialCurrentMax
        upgrades
            .groupBy {
                it.id.toUnLeveled()
            }.values.map { upgrade ->
                upgrade.maxBy { it.id.level }
            }.sortedBy {
                it.order
            }.toList().forEach {
                currentMax = it.processCurrentMax(currentMax = currentMax)
            }
        return currentMax
    }
}
