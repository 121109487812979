package eu.karelhovorka.numbers.exceptions

import eu.karelhovorka.numbers.i18n.i18n
import eu.karelhovorka.numbers.upgradable.GameType
import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade
import eu.karelhovorka.numbers.upgradable.asTranslatable
import eu.karelhovorka.numbers.upgradable.upgrade.toI18NMap

class MissingUpgradeException(val upgrade: Upgrade, val missingUpgrades: Set<GameType>) : RuntimeException(
    "missing-upgrade".i18n(
        upgrade.toI18NMap() + mapOf(
            "missing-upgrades" to missingUpgrades.joinToString(",") {
                it.toUnLeveled().asTranslatable().i18n() + " (${it.level})"
            }
        )
    )
)
