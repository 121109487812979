package eu.karelhovorka.numbers.persistence

import eu.karelhovorka.numbers.State
import kotlinx.browser.localStorage
import kotlinx.serialization.json.Json
import org.w3c.dom.get

class LocalStorageStateRepository(
    val json: Json = Json,
    val key: String = "state",
) : StateRepository {
    override fun load(): State {
        return json.decodeFromString(State.serializer(), localStorage[key] ?: error("no state loaded"))
    }

    override fun save(state: State) {
        val js = json.encodeToString(
            State.serializer(),
            state
        )
        localStorage.setItem(key, js)
    }

    fun clear() {
        localStorage.removeItem(key)
    }

}
