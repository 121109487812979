package eu.karelhovorka.numbers

import eu.karelhovorka.numbers.action.*
import eu.karelhovorka.numbers.exceptions.NotEnoughMoneyForUpgradeException
import eu.karelhovorka.numbers.mod.GameMod
import eu.karelhovorka.numbers.mod.GameModUser
import eu.karelhovorka.numbers.multiplayer.Player
import eu.karelhovorka.numbers.upgradable.*
import eu.karelhovorka.numbers.upgradable.upgrade.AbsoluteIncomeUpgrade
import eu.karelhovorka.numbers.upgradable.upgrade.CurrentMaxUpgrade
import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade
import kotlinx.datetime.Clock
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

class Game(
    val initialState: State,
    val incomeCalculatorByAction: MutableMap<Actions, ActionBasedIncome> = mutableMapOf(),
    val repository: UpgradeRepository,
    val listener: ActionListener = EmptyActionListener,
    val gameListener: GameListener = EmptyGameListener,
    val currentMaxCalculator: CurrentMaxCalculator = CurrentMaxCalculator(),
) : Updatable {

    val upgradeTracker = UpgradeTracker(
        initialUpgrades = initialState.upgrades,
        repository = repository,
    )

    var score: Score = initialState.score

    var currentMoney: Score = initialState.currentMoney
        private set

    var currentMax: Score = initialState.currentMax
        private set

    var player = initialState.player

    var lastActionsByType: MutableMap<String, TimedAction> = initialState.lastActionsByName.toMutableMap()
        private set

    private val calculatedIncomes = initialState.initialIncomesByAction.toMutableMap()

    val gameMod: GameMod = object : GameMod {
        override fun state(): State {
            return this@Game.state()
        }

        override fun addScore(score: HugeNumber, currentMoney: HugeNumber, currentMax: HugeNumber) {
            if (score > 0) {
                this@Game.score += score
                gameListener.onScoreChange(this@Game.score, Actions.NONE)
            }
            if (currentMoney > 0) {
                this@Game.currentMoney += currentMoney
                gameListener.onCurrentMoneyChange(this@Game.score, Actions.NONE)
            }
            if (currentMax > 0) {
                this@Game.currentMax += currentMax
                gameListener.onCurrentMax(this@Game.score, Actions.NONE)
            }
        }

        override fun setScore(score: HugeNumber, currentMoney: HugeNumber, currentMax: HugeNumber) {
            this@Game.score = score
            this@Game.currentMoney = currentMoney
            this@Game.currentMax = currentMax
        }

        override fun upgradeWithoutMoney(gameType: GameType) {
            upgradeTracker.tryUpgrade(repository.get(gameType))
            { upgrade ->
                if (upgrade is AbsoluteIncomeUpgrade) {
                    getActionBasedIncome(upgrade.actions).upgrade(upgrade)
                }
                updateCalculatedIncome(upgrade.actions)
                if (upgrade is GameModUser) {
                    upgrade.attach(this)
                }
                gameListener.onUpgrade(upgrade)
            }
            calculateCurrentMax()
            onAction(Actions.UPGRADE)
        }

        override val upgradeRepository: UpgradeRepository = this@Game.upgradeTracker.repository
        override val upgradeTracker: UpgradeTracker = this@Game.upgradeTracker

    }

    init {
        Actions.values().forEach {
            updateCalculatedIncome(it)
        }
        val initialUpgrades = initialState.upgrades.map {
            repository.get(it)
        }
        initialUpgrades.filterIsInstance<GameModUser>().forEach {
            it.attach(gameMod)
        }
        val upgradesByAction = initialUpgrades.groupBy { it.actions }
        incomeCalculatorByAction.forEach { (action, abi) ->
            upgradesByAction[action]?.filterIsInstance<AbsoluteIncomeUpgrade>()?.forEach {
                abi.upgrade(it)
            }
        }
    }

    fun onAction(action: Actions) {
        lastActionsByType[action.name] = TimedAction(action, Clock.System.now())
        incScore(updateCalculatedIncome(action), action)
        val state = state()
        incomeCalculatorByAction.values.forEach { it.onAction(action, state) }
        listener.onAction(action, state)
    }

    private fun getActionBasedIncome(action: Actions): ActionBasedIncome {
        return incomeCalculatorByAction.getOrPut(action) {
            ActionBasedIncome.empty()
        }
    }

    fun state(): State {
        return State(
            score = score,
            currentMoney = currentMoney,
            initialIncomesByAction = initialState.initialIncomesByAction,
            currentIncomesByAction = calculatedIncomes.toMap(),
            lastActionsByName = lastActionsByType,
            upgrades = upgradeTracker.upgradedIds(),
            currentMax = currentMax,
            start = initialState.start,
            gameId = initialState.gameId,
            player = player,
            version = initialState.version,
        )
    }

    fun updatePlayerName(name: String) {
        player = Player(name = name)
    }

    override fun update(delta: Duration) {
        onAction(Actions.UPDATE)
        gameListener.onUpdate(delta)
        upgradeTracker.upgraded().filterIsInstance<GameListener>().forEach {
            it.onUpdate(delta)
        }
    }

    fun onClick() {
        onAction(Actions.CLICK)
    }

    private fun checkHasEnoughMoney(upgrade: Upgrade) {
        if (upgrade.cost > currentMoney) {
            throw NotEnoughMoneyForUpgradeException(
                upgrade, currentMoney
            )
        }
    }

    fun passiveIncome(): Income {
        return incomes().getValue(Actions.UPDATE)
    }

    fun clickIncome(): Income {
        return incomes().getValue(Actions.CLICK)
    }

    fun upgrade(gameType: GameType) {
        upgradeTracker.tryUpgrade(repository.get(gameType))
        { upgrade ->
            checkHasEnoughMoney(upgrade)
            if (upgrade is AbsoluteIncomeUpgrade) {
                getActionBasedIncome(upgrade.actions).upgrade(upgrade)
            }
            currentMoney -= upgrade.cost
            updateCalculatedIncome(upgrade.actions)
            if (upgrade is GameModUser) {
                upgrade.attach(gameMod)
            }
            gameListener.onUpgrade(upgrade)
        }
        calculateCurrentMax()
        onAction(Actions.UPGRADE)
        //println("upgrade $gameType")
    }

    private fun calculateCurrentMax() {
        val newCurrentMax = currentMaxCalculator.calculate(
            initialState.currentMax,
            upgradeTracker.upgraded().filterIsInstance<CurrentMaxUpgrade>()
        )
        currentMax = initialState.currentMax
        if (currentMax != newCurrentMax) {
            currentMax = newCurrentMax
            gameListener.onCurrentMax(currentMax, Actions.UPGRADE)
        }
    }

    private fun updateCalculatedIncome(action: Actions): Income {
        val income = initialState.initialIncomesByAction[action] ?: Income.empty()
        val calculatedIncome = getActionBasedIncome(action).calculateIncome(income)
        calculatedIncomes[action] = calculatedIncome
        return calculatedIncome
    }


    fun incomes(): Map<Actions, Income> {
        return calculatedIncomes
    }

    private fun incScore(income: Income, action: Actions) {
        if (income.isEmpty()) {
            return
        }
        score += income
        gameListener.onScoreChange(score, action)
        currentMoney += income
        gameListener.onCurrentMoneyChange(score, action)
        if (currentMoney > currentMax) {
            currentMoney = currentMax
        }
    }

    companion object {
        fun empty(vararg upgrades: Upgrade): Game {
            return Game(
                initialState = State.DEFAULT,
                repository = MemoryUpgradeRepository(upgrades.associateBy { it.id }),
            )
        }

        fun empty(map: Map<GameType, Upgrade> = emptyMap()): Game {
            return Game(
                initialState = State.DEFAULT,
                repository = MemoryUpgradeRepository(map),
            )
        }
    }

}
