package eu.karelhovorka.numbers.multiplayer

import eu.karelhovorka.numbers.Score
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class BoardItem(
    val playerName: String,
    val score: Score,
    val minuteEarnRate: Float,
    val started: Instant,
)
