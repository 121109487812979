package eu.karelhovorka.numbers.upgradable

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.Income
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.i18n.addTranslation
import eu.karelhovorka.numbers.i18n.simpleFormat
import eu.karelhovorka.numbers.toHugeNumberOrNull
import kotlinx.serialization.Serializable

@Serializable
data class BasicUpgradeData(
    val gameType: GameType,
    val actions: Actions,
    val income: Income,
    val cost: HugeNumber,
    val order: Int,
    val requiredIds: Set<GameType>,
    val enabled: Boolean,
    val absoluteDifference: Int,
    val descriptionsByLanguageCode: Map<String, String>,
) {

    private fun argumentsForFormat(): Map<String, String> {
        return mapOf(
            "order" to order.toString(),
            "income" to income.toString(),
            "cost" to cost.toString(),
            "absolute" to income.absolute.toString(),
            "relative" to income.relative.toString(),
            "level" to gameType.level.toString(),
            "level1" to (gameType.level + 1).toString(),
            "diff" to absoluteDifference.toString(),
        )
    }

    fun addTranslations() {
        descriptionsByLanguageCode.forEach { (lang, message) ->
            addTranslation(
                lang = lang,
                key = gameType.descriptionLangKey(),
                value = message.simpleFormat(argumentsForFormat())
            )
        }
    }

    companion object {
        fun fromCsvLine(map: Map<String, String>): BasicUpgradeData {
            return with(map) {
                val gameType = GameType.parse(getValue("Id"))
                BasicUpgradeData(
                    gameType = gameType,
                    income = Income(
                        absolute = getValue("Absolute").toHugeNumberOrNull() ?: 0,
                        relative = getValue("Relative").toDoubleOrNull() ?: 1.0,
                    ),
                    actions = Actions.valueOf(getValue("Action")),
                    cost = getValue("Cost").toHugeNumberOrNull() ?: 0,
                    order = getValue("Order").toIntOrNull() ?: 0,
                    requiredIds = parseUpgrades(gameType, get("RequiredIds")),
                    enabled = getValue("Enabled").lowercase().toBoolean(),
                    descriptionsByLanguageCode = parseDescriptions(map),
                    absoluteDifference = get("Absolute difference")?.toIntOrNull() ?: 0
                )
            }
        }

        private fun parseDescriptions(map: Map<String, String>): Map<String, String> {
            return map.filter { (key, value) ->
                key.startsWith("Description-") && value.isNotBlank()
            }.map { (key, value) -> key.substringAfter("Description-") to value }.toMap()
        }

        private fun parseUpgrades(gameType: GameType, text: String?): Set<GameType> {
            if ((text == null || text.isBlank()) && gameType.level == 0) {
                return emptySet()
            }
            if ((text == null || text.isBlank()) && gameType.level > 0) {
                return setOf(gameType.reduceLevel())
            }
            if (gameType.level == 0) {
                return (text?.split(",")?.map { GameType.parse(it) }?.toSet() ?: emptySet())
            }
            return setOf(gameType.reduceLevel()) + (text?.split(",")?.map { GameType.parse(it) }?.toSet() ?: emptySet())
        }
    }
}
