package eu.karelhovorka.numbers.upgradable

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.action.GameListener
import eu.karelhovorka.numbers.exceptions.AlreadyUpgradedException
import eu.karelhovorka.numbers.exceptions.MissingUpgradeException
import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade

class UpgradeTracker(
    initialUpgrades: List<GameType> = emptyList(),
    val repository: UpgradeRepository,
) {

    private val finishedUpgrades: MutableList<GameType> = initialUpgrades.toMutableList()

    fun availableUpgrades(): List<Upgrade> {
        return repository.all().filter {
            canUpgrade(it)
        }
    }

    fun availableForMoney(money: HugeNumber): List<Upgrade> {
        return availableUpgrades().filter {
            it.cost <= money
        }
    }

    fun availableIncludingRequiredBlocked(): List<Upgrade> {
        return repository.all().filter { it.id !in finishedUpgrades }.filter { upgrade ->
            canUpgrade(upgrade) || (upgrade.id.level == 0 || upgrade.id.reduceLevel() in finishedUpgrades)
        }
    }

    fun upgradedIds(): List<GameType> {
        return finishedUpgrades
    }

    fun upgraded(): List<Upgrade> {
        return finishedUpgrades.map { repository.get(it) }
    }


    fun canUpgrade(upgrade: Upgrade): Boolean {
        return upgrade.id !in finishedUpgrades && finishedUpgrades.containsAll(upgrade.requires)
    }

    private fun checkUpgrade(upgrade: Upgrade) {
        if (upgrade.id in finishedUpgrades) {
            throw AlreadyUpgradedException(upgrade)
        }
        if (!finishedUpgrades.containsAll(upgrade.requires)) {
            throw MissingUpgradeException(upgrade, upgrade.requires.subtract(finishedUpgrades))
        }
    }

    fun tryUpgrade(upgrade: Upgrade, block: (Upgrade) -> Unit) {
        checkUpgrade(upgrade)
        block(upgrade)
        upgraded().filterIsInstance<GameListener>().forEach {
            it.onUpgrade(upgrade)
        }
        addUpgrade(upgrade.id)
    }

    internal fun addUpgrade(type: GameType) {
        val upgrade = repository.get(type)
        checkUpgrade(upgrade)
        finishedUpgrades.add(type)
    }
}
