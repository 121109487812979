package eu.karelhovorka.numbers.upgradable

import eu.karelhovorka.numbers.upgradable.upgrade.*

class UpgradeFactory {

    fun fromCSV(map: List<Map<String, String>>): List<Upgrade> {
        return map
            .map { BasicUpgradeData.fromCsvLine(it) }.filter {
                it.addTranslations()
                it.enabled
            }
            .map { getUpgrade(it) }
    }

    fun getUpgrade(basicUpgradeData: BasicUpgradeData): Upgrade {
        return when (basicUpgradeData.gameType.typeId) {
            "Tier", "Notification", "UI" -> {
                SimplestUpgrade.fromSingleUpgrade(basicUpgradeData)
            }
            "CurrentMax" -> {
                CurrentMaxUpgrade.fromSingleUpgrade(basicUpgradeData)
            }
            "Active", "Passive" -> {
                if (basicUpgradeData.gameType.subtypeId == "Absolute") {
                    AbsoluteIncomeUpgrade.fromSingleUpgrade(basicUpgradeData)
                } else {
                    TODO("not implemented ${basicUpgradeData.gameType}")
                }
            }
            "Refund" -> {
                UpgradeRefundUpgrade.fromSingleUpgrade(basicUpgradeData)
            }
            "Auto" -> {
                AutoUpgradeUpgrade.fromSingleUpgrade(basicUpgradeData)
            }
            else -> error("No factory for ${basicUpgradeData.gameType.toUnLeveled()}")
        }
    }

    fun getFactory(basicUpgradeData: BasicUpgradeData): (BasicUpgradeData) -> Upgrade {
        return when (basicUpgradeData.gameType.toUnLeveled()) {
            UnLeveledType.CURRENT_MAX_ABSOLUTE -> {
                TODO("")
            }
            else -> error("No factory for ${basicUpgradeData.gameType.toUnLeveled()}")
        }
    }
}
