package eu.karelhovorka.numbers.config

import eu.karelhovorka.numbers.Game
import eu.karelhovorka.numbers.State
import eu.karelhovorka.numbers.action.ActionListener
import eu.karelhovorka.numbers.action.EmptyActionListener
import eu.karelhovorka.numbers.generated.stateCSV
import eu.karelhovorka.numbers.generated.upgradesCSV
import eu.karelhovorka.numbers.io.drive.parseCsv
import eu.karelhovorka.numbers.persistence.StateRepository
import eu.karelhovorka.numbers.upgradable.MemoryUpgradeRepository
import eu.karelhovorka.numbers.upgradable.UpgradeFactory
import eu.karelhovorka.numbers.upgradable.UpgradeRepository

interface GameFactory {
    fun create(actionListener: ActionListener = EmptyActionListener): Game
}


class StringStateRepository(val stateStr: String = stateCSV, val upgrades: String = upgradesCSV) {
    private fun load(): State {
        return State.fromCsv(parseCsv(stateStr).first())
    }

    fun repository(): UpgradeRepository {
        return MemoryUpgradeRepository(
            map = UpgradeFactory().fromCSV(parseCsv(upgrades)).associateBy { it.id }
        )
    }

    fun toFactory(): GameFactory {
        return LoadGameFactory(
            upgradeRepository = repository(),
            state = load(),
        )
    }
}


