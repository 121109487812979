package eu.karelhovorka.numbers.upgradable.upgrade

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.ZERO_HUGE_NUMBER
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.upgradable.BasicUpgradeData
import eu.karelhovorka.numbers.upgradable.GameType

data class SimplestUpgrade(
    override val id: GameType,
    override val requires: Set<GameType>,
    override val cost: HugeNumber,
    override val actions: Actions,
    override val description: String = generateDescription(id, requires, cost),
    override val order: Int,
) : Upgrade {

    companion object {
        fun fromSingleUpgrade(basicUpgradedata: BasicUpgradeData): SimplestUpgrade {
            return SimplestUpgrade(
                id = basicUpgradedata.gameType,
                requires = basicUpgradedata.requiredIds,
                actions = basicUpgradedata.actions,
                cost = basicUpgradedata.cost,
                order = basicUpgradedata.order,
            )
        }

        fun empty(): SimplestUpgrade {
            return SimplestUpgrade(
                id = GameType.empty(),
                requires = emptySet(),
                cost = ZERO_HUGE_NUMBER,
                actions = Actions.NONE,
                order = 0,
            )
        }
    }
}
