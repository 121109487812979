package eu.karelhovorka.numbers.upgradable.upgrade

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.ZERO_HUGE_NUMBER
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.action.EmptyGameListener
import eu.karelhovorka.numbers.action.GameListener
import eu.karelhovorka.numbers.mod.GameModContainer
import eu.karelhovorka.numbers.mod.GameModUser
import eu.karelhovorka.numbers.upgradable.BasicUpgradeData
import eu.karelhovorka.numbers.upgradable.CounteredCall
import eu.karelhovorka.numbers.upgradable.GameType
import kotlin.time.Duration

data class AutoUpgradeUpgrade(
    override val id: GameType,
    override val requires: Set<GameType>,
    override val cost: HugeNumber,
    override val actions: Actions = Actions.UPDATE,
    override val description: String = generateDescription(id, requires, cost),
    override val order: Int,
    val updatesToUpgrade: Int,
    override val diffValue: Number,
    val gameModContainer: GameModContainer = GameModContainer(),
    val gameListener: GameListener = EmptyGameListener,
) : ValueUpgrade, GameListener by gameListener, GameModUser by gameModContainer {

    val call = CounteredCall(requiredTimes = updatesToUpgrade, initialCounter = 0) {
        gameMod.upgradeTracker.availableUpgrades().minByOrNull { it.cost }?.let {
            gameMod.upgradeWithoutMoney(it.id)
        }
    }

    override val upgradedValue: Number = updatesToUpgrade


    override fun onUpdate(delta: Duration) {
        if (!enabled) {
            return
        }
        call.update()
    }

    var enabled = true

    override fun onUpgrade(upgrade: Upgrade) {
        if (upgrade.id.toUnLeveled() == id.toUnLeveled()) {
            enabled = false
        }
    }

    companion object {

        fun empty(): AutoUpgradeUpgrade {
            return AutoUpgradeUpgrade(
                id = GameType.empty(),
                requires = emptySet(),
                cost = ZERO_HUGE_NUMBER,
                actions = Actions.UPDATE,
                order = 0,
                updatesToUpgrade = 5,
                diffValue = 0,
            )
        }


        fun fromSingleUpgrade(basicUpgradeData: BasicUpgradeData): AutoUpgradeUpgrade {
            return AutoUpgradeUpgrade(
                actions = basicUpgradeData.actions,
                id = basicUpgradeData.gameType,
                requires = basicUpgradeData.requiredIds,
                cost = basicUpgradeData.cost,
                order = basicUpgradeData.order,
                updatesToUpgrade = basicUpgradeData.income.absolute.toInt(),
                diffValue = basicUpgradeData.absoluteDifference,
            )
        }
    }
}
