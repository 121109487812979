package eu.karelhovorka.numbers.mod

class GameModContainer: GameModUser {
    private var gameMod_: GameMod? = null

    override val gameMod: GameMod
        get() = gameMod_ ?: error("GameMod not attached!")

    override fun attach(gameMod: GameMod) {
        gameMod_ = gameMod
    }

    override fun detach() {
        gameMod_ = null
    }

    override fun isAttached(): Boolean {
        return gameMod_ != null
    }
}
