package eu.karelhovorka.numbers

typealias RelativeNumber = Double
typealias Score = HugeNumber


val expToSign = mapOf(
    1 to "k",
    2 to "m",
    3 to "g",
    4 to "t",
    5 to "p",
    6 to "e",
    7 to "z",
    8 to "y",
    9 to "r",
    10 to "q",
)

fun HugeNumber.toScore(): Score {
    return this
}

fun Double.toScore(): HugeNumber {
    return toHugeNumber()
}

fun Int.toScore(): HugeNumber {
    return toHugeNumber()
}

fun String.toScore(): HugeNumber {
    return toHugeNumber()
}

fun String.toScoreOrNull(): HugeNumber? {
    return toHugeNumberOrNull()
}

fun Int.short(): String {
    return toLong().short()
}

operator fun Score.plus(income: Income): Score {
    return ((this + income.absolute) * income.relative).toHugeNumber()
}

operator fun Score.plus(value: HugeNumber): Score {
    return this + value
}

operator fun Score.minus(value: HugeNumber): Score {
    return this - value
}


