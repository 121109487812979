package eu.karelhovorka.numbers.upgradable

class CounteredCall(private val requiredTimes: Int = 60, initialCounter: Int = 0, val block: () -> Unit = {}) {

    var counter = initialCounter
        private set

    fun update() {
        counter += 1
        if (counter >= requiredTimes) {
            counter = 0
            block()
        }
    }

}
