package eu.karelhovorka.numbers.multiplayer

typealias GameId = String

interface ScoreService {
    suspend fun submit(scoreSubmission: ScoreSubmission): ScoreSubmissionResponse
    suspend fun top(): List<BoardItem>
}


fun generateRandomString(length: Int): String {
    val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}
