package eu.karelhovorka.numbers.upgradable.upgrade

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.Score
import eu.karelhovorka.numbers.ZERO_HUGE_NUMBER
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.toHugeNumber
import eu.karelhovorka.numbers.upgradable.BasicUpgradeData
import eu.karelhovorka.numbers.upgradable.GameType

data class CurrentMaxUpgrade(
    override val id: GameType,
    override val requires: Set<GameType>,
    override val cost: HugeNumber,
    override val actions: Actions,
    override val description: String = generateDescription(id, requires, cost),
    override val order: Int,
    val extraMax: HugeNumber,
    override val diffValue: Number,
) : ValueUpgrade {

    fun processCurrentMax(currentMax: Score): Score {
        return currentMax + extraMax
    }


    override val upgradedValue: Number = extraMax

    companion object {
        fun fromSingleUpgrade(basicUpgradeData: BasicUpgradeData): CurrentMaxUpgrade {
            return CurrentMaxUpgrade(
                id = basicUpgradeData.gameType,
                requires = basicUpgradeData.requiredIds,
                actions = basicUpgradeData.actions,
                cost = basicUpgradeData.cost,
                extraMax = basicUpgradeData.income.absolute,
                order = basicUpgradeData.order,
                diffValue = basicUpgradeData.absoluteDifference,
            )
        }

        fun empty(extraMax: HugeNumber = 1.toHugeNumber()): CurrentMaxUpgrade {
            return CurrentMaxUpgrade(
                id = GameType.empty(),
                requires = emptySet(),
                cost = ZERO_HUGE_NUMBER,
                actions = Actions.NONE,
                order = 0,
                extraMax = extraMax,
                diffValue = extraMax,
            )
        }
    }
}
