package eu.karelhovorka.numbers.i18n


val leftBracket = "{"

val rightBracket = "}"

class I18NInfo {

    val defaultLanguage: String = "en"


    var currentLang = defaultLanguage

    fun chooseBest(preferredLanguages: List<String>) {
        val lang = preferredLanguages.firstOrNull { it in transByLang.keys }
        setLang(lang)
    }

    fun setLang(lang: String?) {
        if (lang != null) {
            currentLang = lang
        }
    }

    fun containsKey(key: String): Boolean {
        return transByLang[currentLang]?.containsKey(key) == true
    }
}

val I18N = I18NInfo()

interface Translatable {
    val translateKey: String
    val arguments: Map<String, String>
}

data class DataTranslatable(
    override val translateKey: String,
    override val arguments: Map<String, String> = emptyMap(),
) : Translatable


fun Translatable.i18n(lang: String = I18N.currentLang): String {
    return translateKey.i18n(lang, arguments)
}

fun String.toKey(): String {
    return lowercase().replace("_", "-")
}

private val transByLang = mutableMapOf<String, MutableMap<String, String>>(
    "cs" to mutableMapOf(
    )
)

val translationsByLang: Map<String, Map<String, String>>
    get() {
        return transByLang
    }

fun addTranslation(lang: String, key: String, value: String) {
    transByLang.getOrPut(lang) { mutableMapOf() }.apply {
        if (containsKey(key)) {
            //println("lang $lang already contains $key")
        }
        put(key, value)
    }
}

fun addTranslation(lang: String, translations: Map<String, String>) {
    transByLang.getOrPut(lang) { mutableMapOf() }.putAll(translations)
}

fun String.i18n(lang: String = I18N.currentLang, vararg arguments: String?): String {
    return __i18n(this, lang, *arguments)
}

fun String.i18n(lang: String = I18N.currentLang, arguments: Map<String, String>): String {
    return _i18n(this, lang, arguments)
}

fun String.i18n(arguments: Map<String, String>): String {
    return _i18n(this, I18N.currentLang, arguments)
}

fun String.simpleFormat(arguments: Map<String, String> = emptyMap()): String {
    var result = this
    arguments.forEach {
        result = result.replace("${leftBracket}${it.key}${rightBracket}", it.value)
    }
    return result
}

fun String.simpleFormat(arguments: Array<out Any?> = emptyArray()): String {
    var result = this
    arguments.forEachIndexed { index, it ->
        result = result.replace("${leftBracket}${index}${rightBracket}", it.toString())
    }
    return result
}

private fun _i18n(
    message: String,
    lang: String = I18N.currentLang,
    arguments: Map<String, String> = emptyMap()
): String {
    return transByLang.get(lang)?.get(message)?.simpleFormat(arguments)
        ?: "_$message($lang)_".apply { println("Missing: $this") }
}

private fun __i18n(message: String, lang: String = I18N.currentLang, vararg arguments: String?): String {
    return transByLang.get(lang)?.get(message)?.simpleFormat(arguments)
        ?: "_$message($lang)_".apply { println("Missing: $this") }
}
