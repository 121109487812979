
package eu.karelhovorka.numbers.generated

val i18nCSV = """key,cs,en
the-number,Číslo,The Number
upgrades-available,Možná vylepšení,Upgrades available
upgrades-done,Hotová vylepšení,Upgrades done
income-label-click,Příjem kliknutí,Click income
income-label-update,Pasivní příjem,Passive income
action-click,Klikni {income-click},Click {income-click}
upgrade-Passive/Absolute,Pasivní vylepšení,Passive upgrade
upgrade-Active/Absolute,Vylepšení kliku,Click upgrade
upgrade-description-Passive/Absolute,Zvedne pasivní příjem o {absolute}/s.,Increases passive income by {absolute}/s.
upgrade-description-Active/Absolute,Zvedne příjem za klik o {absolute}/s. Cena {cost}.,Increases click income by {absolute}/s.
label-price,Cena: {cost},Price: {cost}
label-type,Druh: {type},Type: {type}
not-enough-money-for-upgrade,Nedostatek peněz ({currentMoney}) na vylepšení '{upgrade}' ({cost}).,Not enough money ({currentMoney}) for upgrade '{upgrade}' ({cost}).
upgrade-Tier/Base,Hlavní vylepšení,Tier upgrade
upgrade-Notification/Title,Notifikace v titulku,Notifications in title
upgrade-CurrentMax/Absolute,Maximální číslo,Maximum number
missing-upgrade,Chybí vylepšení: {missing-upgrades}.,Missing upgrades: {missing-upgrades} to build {upgrade}
already-upgraded,Vylepšení '{upgrade}' ({level}) už je hotové.,Upgrade '{upgrade}' ({level}) is already done.
upgrade-UI/BiggerButton,Větší klikátko.,Bigger click button.
restart-game,Restartovat hru,Restart game
score,Skore,Score
player-name,Jméno hráče,Player name
minute-earn-rate,Průměrný příjem za minutu,Average earning per minute
high-score,Nejlepší skore,High score
upgrade-Refund/Upgrade,Zlevnění vylepšení,Upgrade cashback
started-at,Začátek,Started at
upgrade-Auto/Upgrade,Auto vylepšení,Auto upgrade"""                
                