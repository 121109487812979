package eu.karelhovorka.numbers.upgradable.upgrade

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.i18n.I18N
import eu.karelhovorka.numbers.i18n.i18n
import eu.karelhovorka.numbers.short
import eu.karelhovorka.numbers.signSymbol
import eu.karelhovorka.numbers.upgradable.GameObject
import eu.karelhovorka.numbers.upgradable.GameType
import eu.karelhovorka.numbers.upgradable.asTranslatable
import eu.karelhovorka.numbers.upgradable.descriptionLangKey

interface Upgrade : GameObject {
    val requires: Set<GameType>
    val cost: HugeNumber
    val description: String
    val actions: Actions
    val order: Int
}

fun Number.formatForBadge(): String {
    if (this is Long) {
        return signSymbol() + short()
    }
    if (this is Int) {
        return signSymbol() + short()
    }
    error("don't know how to format $this ${this::class}")
}

fun Upgrade.toI18NMap(): Map<String, String> {
    return mapOf(
        "id" to id.toString(),
        "level" to id.level.toString(),
        "upgrade" to id.toUnLeveled().asTranslatable().i18n(),
        "cost" to cost.toString(),
    )
}

fun generateDescription(id: GameType, requires: Set<GameType>, cost: HugeNumber): String {
    val result = StringBuilder()
    if (I18N.containsKey(id.descriptionLangKey())) {
        result.appendLine(id.descriptionLangKey().i18n())
    }
    val unLeveledType = id.toUnLeveled()
    val interestingRequires = requires.filter { it.toUnLeveled() != unLeveledType }
    if (interestingRequires.isNotEmpty()) {
        result.appendLine(
            interestingRequires.joinToString(",", prefix = "Requires: ") {
                it.toUnLeveled().asTranslatable().i18n() + " (${it.level})"
            })
    }
    return result.toString().lines().joinToString("\r\n")
}


