package eu.karelhovorka.numbers

import eu.karelhovorka.numbers.config.LoadGameFactory
import eu.karelhovorka.numbers.config.StringStateRepository
import eu.karelhovorka.numbers.generated.i18nCSV
import eu.karelhovorka.numbers.i18n.I18N
import eu.karelhovorka.numbers.i18n.parseI18N
import eu.karelhovorka.numbers.io.drive.parseCsv
import eu.karelhovorka.numbers.persistence.LocalStorageLastUpdateRepository
import eu.karelhovorka.numbers.persistence.LocalStorageStateRepository
import kotlinx.browser.window
import org.w3c.dom.url.URL


fun main() {
    println("Hello Numbers!")
    parseI18N(parseCsv(i18nCSV))
    val url = URL(window.location.href)
    url.searchParams.get("lang")?.let { lang ->
        I18N.currentLang = lang
    }
    processI18N()
    val listener = JsActionListener()
    val savedState: State? = try {
        LocalStorageStateRepository().load()
    } catch (e: Exception) {
        e.printStackTrace()
        null
    }
    val stringRepository = StringStateRepository()

    val gameFactory = if (savedState == null) {
        stringRepository.toFactory()
    } else {
        LoadGameFactory(state = savedState, upgradeRepository = stringRepository.repository())
    }
    val game = gameFactory.create(actionListener = listener)
    listener.attachGame(game)
    val loop = SimpleLoop(updatable = game)
    listener.attachLoop(loop = loop)
    loop.start()
    println(game.state())
    Unit
}
