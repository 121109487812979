package eu.karelhovorka.numbers.action

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade
import kotlin.time.Duration

interface GameListener {
    fun onUpdate(delta: Duration)
    fun onUpgrade(upgrade: Upgrade)
    fun onScoreChange(hugeValue: HugeNumber, sourceAction: Actions)
    fun onCurrentMoneyChange(hugeValue: HugeNumber, sourceAction: Actions)
    fun onCurrentMax(hugeValue: HugeNumber, sourceAction: Actions)
}


object EmptyGameListener : GameListener {

    override fun onUpdate(delta: Duration) {
    }

    override fun onUpgrade(upgrade: Upgrade) {
    }

    override fun onScoreChange(hugeValue: HugeNumber, sourceAction: Actions) {
    }

    override fun onCurrentMoneyChange(hugeValue: HugeNumber, sourceAction: Actions) {
    }

    override fun onCurrentMax(hugeValue: HugeNumber, sourceAction: Actions) {
    }

}
