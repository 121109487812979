package eu.karelhovorka.numbers.upgradable.upgrade

import eu.karelhovorka.numbers.*
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.action.EmptyGameListener
import eu.karelhovorka.numbers.action.GameListener
import eu.karelhovorka.numbers.mod.GameModContainer
import eu.karelhovorka.numbers.mod.GameModUser
import eu.karelhovorka.numbers.upgradable.BasicUpgradeData
import eu.karelhovorka.numbers.upgradable.GameType

data class UpgradeRefundUpgrade(
    override val id: GameType,
    override val requires: Set<GameType>,
    override val cost: HugeNumber,
    override val actions: Actions,
    override val description: String = generateDescription(id, requires, cost),
    override val order: Int,
    val refund: Int,
    override val upgradedValue: Number = refund,
    override val diffValue: Number,
    val gameModContainer: GameModContainer = GameModContainer(),
    val gameListener: GameListener = EmptyGameListener,
) : ValueUpgrade, GameListener by gameListener, GameModUser by gameModContainer {

    override fun onUpgrade(upgrade: Upgrade) {
        refundUpgrade()
    }

    private fun refundUpgrade() {
        gameMod.addScore(score = refund.toHugeNumber(), currentMoney = refund.toHugeNumber())
    }

    companion object {
        fun empty(refund: Int = 1): UpgradeRefundUpgrade {
            return UpgradeRefundUpgrade(
                id = GameType.empty(),
                requires = emptySet(),
                cost = ZERO_HUGE_NUMBER,
                actions = Actions.NONE,
                order = 0,
                refund = refund,
                upgradedValue = refund,
                diffValue = refund,
            )
        }

        fun fromSingleUpgrade(basicUpgradeData: BasicUpgradeData): Upgrade {
            return UpgradeRefundUpgrade(
                actions = basicUpgradeData.actions,
                id = basicUpgradeData.gameType,
                requires = basicUpgradeData.requiredIds,
                cost = basicUpgradeData.cost,
                order = basicUpgradeData.order,
                refund = basicUpgradeData.income.absolute.toInt(),
                diffValue = basicUpgradeData.absoluteDifference,
            )
        }
    }
}
