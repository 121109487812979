package eu.karelhovorka.numbers.action

import eu.karelhovorka.numbers.i18n.DataTranslatable
import eu.karelhovorka.numbers.i18n.Translatable
import kotlinx.serialization.Serializable

@Serializable
enum class Actions {
    CLICK, BOOST_INCOME, UPGRADE, UPDATE, NONE;

    val css: String
        get() = name.lowercase()
}


fun Actions.asTranslatable(): Translatable {
    return DataTranslatable(translateKey = "income-label-${this@asTranslatable.css}")
}
