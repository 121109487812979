package eu.karelhovorka.numbers.multiplayer

import kotlinx.serialization.Serializable

@Serializable
data class ScoreSubmissionResponse(
    val result: String = ""
) {
    companion object {
        val OK = ScoreSubmissionResponse("OK")
    }
}
