package eu.karelhovorka.numbers.mod

import eu.karelhovorka.numbers.HugeNumber
import eu.karelhovorka.numbers.State
import eu.karelhovorka.numbers.ZERO_HUGE_NUMBER
import eu.karelhovorka.numbers.upgradable.GameType
import eu.karelhovorka.numbers.upgradable.UpgradeRepository
import eu.karelhovorka.numbers.upgradable.UpgradeTracker

interface GameMod {
    fun state(): State

    fun addScore(
        score: HugeNumber = ZERO_HUGE_NUMBER,
        currentMoney: HugeNumber = ZERO_HUGE_NUMBER,
        currentMax: HugeNumber = ZERO_HUGE_NUMBER
    )

    fun setScore(
        score: HugeNumber = ZERO_HUGE_NUMBER,
        currentMoney: HugeNumber = ZERO_HUGE_NUMBER,
        currentMax: HugeNumber = ZERO_HUGE_NUMBER
    )

    fun upgradeWithoutMoney(gameType: GameType)

    val upgradeRepository: UpgradeRepository
    val upgradeTracker: UpgradeTracker
}


