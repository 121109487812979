package eu.karelhovorka.numbers.io.drive

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlinx.serialization.json.Json


fun parseCsv(text: String): List<Map<String, String>> {
    val lines = text.lines()
    val header = lines.first().split(",")
    val content = lines.subList(1, lines.size)
    return content.map { line ->
        val cells = line.split(",")
        cells.mapIndexed { index, cell ->
            header[index] to cell
        }.toMap()
    }
}

abstract class SimpleHttpClient() {
    abstract suspend fun get(url: String): Deferred<List<Map<String, String>>>

    open fun getUrl(
        sheetId: String,
        tabName: String,
        tabMapping: Map<String, Int>
    ): String {
        return "https://spreadsheets.google.com/feeds/list/${sheetId}/${tabMapping[tabName]!!}/public/values?alt=json"
    }

    open suspend fun downloadSheet(
        sheetId: String,
        tabName: String,
        tabMapping: Map<String, Int>
    ): Deferred<List<Map<String, String>>> {
        return get(getUrl(sheetId, tabName, tabMapping))
    }
}

class KtorSimpleHttpClient(
    val client: HttpClient = HttpClient() {
        install(ContentNegotiation) {
            json(
                Json {
                    prettyPrint = true
                    isLenient = true
                    ignoreUnknownKeys = true
                },
            )
        }
    }
) : SimpleHttpClient() {
    override suspend fun get(url: String): Deferred<List<Map<String, String>>> {
        return GlobalScope.async<List<Map<String, String>>> {
            val obj = client.get(url) {
            }.body<String>()
            parseCsv(obj)
        }
    }

}
