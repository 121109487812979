package eu.karelhovorka.numbers

import eu.karelhovorka.numbers.action.ActionListener
import eu.karelhovorka.numbers.action.Actions
import eu.karelhovorka.numbers.action.Actions.*
import eu.karelhovorka.numbers.i18n.i18n
import eu.karelhovorka.numbers.multiplayer.client.ScoreServiceClient
import eu.karelhovorka.numbers.multiplayer.toScoreSubmission
import eu.karelhovorka.numbers.persistence.LocalStorageStateRepository
import eu.karelhovorka.numbers.tools.*
import eu.karelhovorka.numbers.upgradable.*
import eu.karelhovorka.numbers.upgradable.UnLeveledType.Companion.BIGGER_BUTTON_UNLEVELED
import eu.karelhovorka.numbers.upgradable.UnLeveledType.Companion.NOTIFY_UNLEVELED
import eu.karelhovorka.numbers.upgradable.upgrade.Upgrade
import eu.karelhovorka.numbers.upgradable.upgrade.ValueUpgrade
import eu.karelhovorka.numbers.upgradable.upgrade.formatForBadge
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import kotlin.coroutines.CoroutineContext
import kotlin.math.roundToInt


class JsActionListener(val root: HTMLElement = document.getElementById("game") as HTMLElement) : ActionListener,
    CoroutineScope {

    val client = ScoreServiceClient()

    override val coroutineContext: CoroutineContext = Job()

    val score = root.queryHtmlSelectorAll<HTMLElement>(".score")
    val shortScore = root.queryHtmlSelectorAll<HTMLElement>(".score-short")
    val currentMax = root.querySelectorAll(".current-max").asHtmlList()
    val currentMoney = root.querySelectorAll(".current-money").asHtmlList()
    val currentMoneyShort = root.querySelectorAll(".current-money-short").asHtmlList()
    val upgradesDone = root.querySelectorAll(".upgrades-done").asHtmlList()
    val upgradesAvailable = root.querySelectorAll(".upgrades-available").asHtmlList()
    val messages = root.queryHtmlSelector<HTMLElement>(".messages")

    val button = root.querySelectorAll(".click-button").asHtmlList().filterIsInstance<HTMLButtonElement>()
    val yourName = root.queryHtmlSelector<HTMLInputElement>("#your-name")

    val storage = LocalStorageStateRepository()

    init {
        messages.hide()
        button.forEach {
            it.addClickListener {
                reportError {
                    game.onClick()
                }
            }
        }
        root.queryHtmlSelectorAll<HTMLElement>(".restart-button").forEach {
            it.addClickListener {
                loop.stop()
                storage.clear()
                window.location.reload()
            }
        }
        yourName.addEventListener("change", {
            game.updatePlayerName(yourName.value)
        })
        reloadTop10()
    }

    private fun reportError(block: () -> Unit) {
        try {
            block()
        } catch (e: Exception) {
            e.printStackTrace()
            displayError(e)
        }
    }

    var displayJob: Job? = null
    private fun displayError(e: Exception) {
        e.message?.let {
            displayJob?.cancel()
            messages.innerHTML = it
            messages.show()
            displayJob = launch {
                delay(4000L)
                displayJob = null
                messages.hide()
            }
        }
    }

    lateinit var game: Game

    fun attachGame(game: Game) {
        this.game = game
        yourName.value = game.player.name
        updateUpgrades(game.state())
        manualUpgradeCheck(game.state())
    }

    override fun onAction(action: Actions, state: State) {
        when (action) {
            CLICK -> onClick(state)
            BOOST_INCOME -> {}
            UPGRADE -> {
                manualUpgradeCheck(state)
                updateUpgrades(state)
                updateScores(state)
                launch {
                    client.submit(game.toScoreSubmission())
                }
            }

            UPDATE -> {
                onUpdate(state)
                updateScores(state)
                updateIncomes()
                updateProgressIndicators(state)
                storage.save(state)
                topCounter.update()
                updateScore.update()
            }

            NONE -> {}
        }
    }

    private fun manualUpgradeCheck(state: State) {
        state.upgradeLevelOf(BIGGER_BUTTON_UNLEVELED)?.let { buttonLevel ->
            button.forEach { buttonElement ->
                buttonElement.addClass("upgrade-level-${buttonLevel}")
            }
        }

    }

    fun onClick(state: State) {
        updateScores(state)
        showPopup(
            game.incomes().getValue(CLICK).toString(),
            document.queryHtmlSelector<HTMLElement>(".click-animation-parent")
        )
    }

    fun onUpdate(state: State) {
        showPopup(
            game.incomes().getValue(UPDATE).toString(),
            document.queryHtmlSelector<HTMLElement>(".update-animation-parent")
        )
        state.upgradeLevelOf(NOTIFY_UNLEVELED)?.let { notifyLevel ->
            if (notifyLevel >= 1) {
                if (state.currentMoney == state.currentMax) {
                    document.title = "(MAX) ${"the-number".i18n()}"
                } else {
                    document.title = "(${state.currentMoney.short()}) ${"the-number".i18n()}"
                }
            } else if (notifyLevel >= 0) {
                if (state.currentMoney == state.currentMax) {
                    document.title = "(MAX) ${"the-number".i18n()}"
                } else {
                    document.title = "the-number".i18n()
                }
            }
        }
    }

    fun showPopup(text: String, parent: HTMLElement) {
        val element = document.createHtmlElement<HTMLDivElement>("div")
        element.innerHTML = text
        element.addClass("show-popup")
        parent.appendChild(element)
        element.addClass("animating")
        launch {
            delay(5000)
            element.remove()
        }
    }

    fun showBoost(action: Actions) {
        root.querySelectorAll(".income-${action.css}.wants-animation").asHtmlList().forEach { element ->
            val classToAdd = if (element.hasClass("animated")) {
                "animated-2"
            } else {
                "animated"
            }
            element.removeClass("animated", "animated-2")
            element.addClass(classToAdd)
        }
    }

    private fun updateScores(state: State) {
        score.forEach { it.innerText = state.score.toString() }
        shortScore.forEach {
            it.innerText = state.score.short()
            it.title = state.score.toString()
        }
        currentMax.forEach {
            it.innerText = state.currentMax.short()
            it.title = state.currentMax.toString()
        }
        currentMoney.forEach { it.innerText = state.currentMoney.toString() }
        currentMoneyShort.forEach {
            it.innerText = state.currentMoney.short()
            it.title = state.currentMoney.toString()
        }
    }

    private fun updateIncomes() {
        game.incomes().filter { !it.value.isEmpty() }.forEach { (action, income) ->
            root.querySelectorAll(".income-${action.css}").asHtmlList().forEach { element ->
                element.innerHTML = income.toString()
            }
        }
    }

    fun badge(bg: String, value: Any?): String {
        if (value == null) return ""
        return """<span class="badge $bg">${value}</span>"""
    }

    fun progressWidth(upgrade: Upgrade, currentMoney: Score): String {
        if (upgrade.cost <= currentMoney) {
            100
        } else {
            (currentMoney.toDouble() / upgrade.cost.toDouble() * 100.0).roundToInt()
        }.let {
            return "$it%"
        }
    }

    fun updateProgressIndicators(state: State) {
        root.queryHtmlSelectorAll<HTMLElement>(".progress-indicator").forEach { element ->
            val gameType = GameType.parse(element.getAttribute("data-upgrade-id")!!)
            val upgrade = game.repository.get(gameType)
            element.style.width = progressWidth(upgrade, state.currentMoney)
        }
    }

    private fun updateUpgrades(state: State) {
        upgradesDone.forEach { element ->

            game.upgradeTracker.repository.all().map { it.id }.groupBy { it.toUnLeveled() }
                .map { (unleveledType, gameTypes) ->
                    val gt = gameTypes.joinToString("") {
                        val bg = if (it in state.upgrades) {
                            "bg-dark"
                        } else {
                            "bg-light text-dark"
                        }
                        """<li class="list-inline-item">
                    <span class="badge $bg upgrade-done"
                        data-bs-toggle="tooltip" 
                        title="${game.repository.get(it).description}"                     
                     disabled
                        >${it.level}</span></li>"""
                    }
                    """
                    <li class="list-group-item">${unleveledType.asTranslatable().i18n()}
                        <ul class="list-inline">
                            $gt
                        </ul>
                    </li>
                """.trimIndent()
                }.joinToString("") { it }.let {
                    element.innerHTML = it
                }
        }
        upgradesAvailable.forEach {
            it.innerHTML = game.upgradeTracker.availableIncludingRequiredBlocked()
                .joinToString("") { upgrade ->
                    """<li class="list-group-item">

                    <div class="progress-indicator progress-indicator-${upgrade.id.css()}" data-upgrade-id="${upgrade.id}" style="width: ${
                        progressWidth(
                            upgrade,
                            state.currentMoney
                        )
                    }"></div>

                    <button id="upgrade-${upgrade.id.css()}" 
                        class="btn btn-primary upgrade-available" 
                        data-bs-toggle="tooltip" 
                        title="${upgrade.description}"
                        >${
                        upgrade.id.toUnLeveled().asTranslatable().i18n()
                    } 
                        ${badge("bg-dark", upgrade.id.level)}
                        <span class="float-end">
                        ${
                        badge(
                            "bg-success", if (upgrade is ValueUpgrade) {
                                upgrade.diffValue.formatForBadge()
                            } else {
                                null
                            }
                        )
                    }
                    ${badge("bg-warning text-dark", upgrade.cost)}
                    </span>
               
                    </button>
                    
                    </li>"""
                }
            game.upgradeTracker.availableIncludingRequiredBlocked().forEach { incomeUpgrade ->
                it.querySelector("#upgrade-${incomeUpgrade.id.css()}")!!.addClickListener {
                    reportError {
                        game.upgrade(incomeUpgrade.id)
                    }
                }
            }
        }
        root.queryHtmlSelectorAll<HTMLButtonElement>(".click-button").forEach { button ->
            button.innerHTML = "action-click".i18n(mapOf("income-click" to game.incomes().getValue(CLICK).toString()))
        }
        document.queryHtmlSelectorAll<HTMLElement>(".tooltip").forEach {
            it.remove()
        }
        enableTooltips()
    }

    lateinit var loop: Loop<Game>

    fun attachLoop(loop: Loop<Game>) {
        this.loop = loop
        this.game = loop.updatable
    }

    val topCounter = CounteredCall(requiredTimes = 6) {
        reloadTop10()
    }

    val updateScore = CounteredCall(requiredTimes = 5) {
        launch {
            println("submit score!")
            try {
                client.submit(game.toScoreSubmission())
            } catch (e: Exception) {
                e.printStackTrace()
            }
        }
    }

    fun reloadTop10() = launch {
        document.queryHtmlSelector<HTMLElement>(".top-10").innerHTML = client.top().joinToString(separator = "") {
            """
                <tr>
                    <td>${it.playerName} </td>
                    <td class="max-score">${it.score.short()} </td>
                    <td>${it.minuteEarnRate} </td>
                    <td>${it.started} </td>
                </tr>
            """.trimIndent()
        }
    }
}

