package eu.karelhovorka.numbers.multiplayer

import eu.karelhovorka.numbers.Score
import eu.karelhovorka.numbers.State
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class GameScore(
    val id: GameId,
    val score: Score,
    val started: Instant,
    val now: Instant,
) {

    val minuteEarnRate: Float
        get() {
            val minutes = (now - started).inWholeMinutes
            if (minutes <= 0) {
                return 0f
            }
            return (score.toDouble() / minutes.toDouble()).toFloat()
        }

    companion object {
        fun create(state: State, now: Instant = Clock.System.now()): GameScore {
            return GameScore(
                id = state.gameId,
                score = state.score,
                started = state.start,
                now = now,
            )
        }
    }
}
