package eu.karelhovorka.numbers

import kotlinx.serialization.Serializable

@Serializable
data class Income(
    val absolute: HugeNumber = 0,
    val relative: RelativeNumber = 1.0,
) {

    fun isEmpty(): Boolean {
        return absolute == 0.toHugeNumber() && relative == 1.0
    }

    operator fun plus(income: Income): Income {
        return Income(absolute + income.absolute, relative * income.relative)
    }

    override fun toString(): String {
        if (isEmpty()) {
            return "0"
        }
        if (relative == 1.0) {
            return "+${absolute}"
        }
        if (absolute == 0.toHugeNumber()) {
            return "*${relative}"
        }
        return "+${absolute}*${relative}"
    }

    companion object {
        fun empty(): Income {
            return Income()
        }
    }
}
